import { useEffect, useState } from "react"
import { getQualifications, updateQualification } from "./SalaryAPI";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { GrUpdate } from "react-icons/gr";

export default function Qualification({ jwt }){

    const [qualifications, setQualifications] = useState([]);

    useEffect(() => {

        async function didMount() {
            
            const response = await getQualifications(jwt);

            setQualifications(response ?? []);
        }

        didMount();
    }, []);

    const Item = ({ item }) => {

        const[value, setValue] = useState(item.value);
        const[isUpdate, setIsUpdate] = useState(false);

        const updateValue = async(newValue) => {

            const isUpdate = await updateQualification(jwt, item.id, newValue);

            if(isUpdate)
            {
                item.value = newValue;
                setIsUpdate(false);
                setValue(newValue);
            }
            else alert("К сожалению что-то пошло не так");
        }

        return <InputGroup>
            <InputGroup.Text>{item.name}</InputGroup.Text>
            <Form.Control
                required
                type='number'
                value={value}
                onChange={(e) => setValue(+e.target.value) || setIsUpdate(item.value != +e.target.value)}
            />
            {
                isUpdate ? 
                <Button onClick={() => updateValue(+value)} variant="success">
                    <GrUpdate/>
                </Button> 
                :''
            }
        </InputGroup>
    }

    return <div>
        <h3>Квалификации</h3>
        {
            qualifications && qualifications.length > 0 ?
            qualifications.map(o => <Item key={o.id} item={o}/>): 
            <Alert variant="warning">К сожалению здесь ничего нет</Alert>
        }
    </div>
}