import { HttpData, HttpNoData } from "../../../Core";

// use and create
export async function createPlace(name, isMarketPlace, jwt) {
    
    const response = await HttpData(`/api/v1/products/places`, 'POST', jwt, {
        name: name,
        isMarketPlace: isMarketPlace
    });

    return response.data;
}

// use and create
export async function getPlaces(jwt) {
    
    const response = await HttpNoData(`/api/v1/products/places`, 'GET', jwt);

    return response.data;
}

// use and create
export async function updatePlace(id, name, isMarketPlace, jwt) {
    
    const response = await HttpData(`/api/v1/products/places/${id}`, 'PUT', jwt, {
        name: name,
        isMarketPlace: isMarketPlace
    });

    return response.statusSuccessful;
}

// use and create
export async function deletePlace(id, statusSends, newPlace, jwt) {
    
    const response = await HttpNoData(`/api/v1/products/places/${id}?${statusSends == 'delete' ? 'sends=delete' : `sends=move&newPlace=${newPlace}`}`, 'DELETE', jwt);

    return response.statusSuccessful;
}

// use and create
export async function createProductCategory(name, priority, jwt) {
    
    const response = await HttpData(`/api/v1/products/categories`, 'POST', jwt, {
        name: name,
        priority: priority
    });

    return response.data;
}

// use and create
export async function getProductCategories(jwt) {
    
    const response = await HttpNoData(`/api/v1/products/categories`, 'GET', jwt);

    return response.data;
}

// use and create
export async function updateProductCategory(id, name, priority, jwt) {
    
    const response = await HttpData(`/api/v1/products/categories/${id}`, 'PUT', jwt, {
        name: name,
        priority: priority
    });

    return response.statusSuccessful;
}

// use and create
export async function deleteProductCategory(id, statusProducts, newCategory, jwt) {
    
    const response = await HttpNoData(`/api/v1/products/categories/${id}?${statusProducts == 'delete' ? 'products=delete' : `products=move&newCategory=${newCategory}`}`, 'DELETE', jwt);

    return response.statusSuccessful;
}

// use and create
export async function createProductSend(productId, value, date, placeId, jwt) {
    
    const response = await HttpData(`/api/v1/productsSends/${date}`, 'POST', jwt, {
        productId: productId,
        value: value, 
        placeId: placeId
    });

    return response.data;
}

// use and create
export async function getProductsSends(date, placeId, jwt) {  
    
    const response = await HttpNoData(`/api/v1/productsSends/${date}?placeId=${placeId}`, 'GET', jwt);

    return response.data;
}

// use and create
export async function updateProductSend(id, value, jwt) {
    
    const response = await HttpNoData(`/api/v1/productsSends/${id}/${value}`, 'PUT', jwt);

    return response.statusSuccessful;
}

// use and create
export async function deleteProductSend(id, jwt) {
    
    const response = await HttpNoData(`/api/v1/productsSends/${id}`, 'DELETE', jwt);

    return response.statusSuccessful;
}

// use and create
export async function createProduct(body, jwt) {
    
    const response = await HttpData(`/api/v1/products`, 'POST', jwt, body);

    return response.data;
}

// use and create
export async function getProducts(jwt) {
    
    const response = await HttpNoData(`/api/v1/products`, 'GET', jwt);

    return response.data;
}

// use and create
export async function updateProduct(id, body, jwt) {
    
    const response = await HttpData(`/api/v1/products/${id}`, 'PUT', jwt, body);

    return response.statusSuccessful;
}

// use and create
export async function deleteProduct(id, jwt) {
    
    const response = await HttpNoData(`/api/v1/products/${id}`, 'DELETE', jwt);

    return response.statusSuccessful;
}