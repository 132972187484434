import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { HttpData } from "../../../../Core";

export default function CategoryModalCreate({ jwt, show, types, onHide, onCreated }){

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [typeId, setTypeId] = useState(types.length > 0 ? types[0].id : -1);
    const [countView, setCountView] = useState(1);

    const onSubmit = async(e) => {
        e.preventDefault();

        const response = await HttpData('/api/v1/calculateOrderCost/productCategories', 'POST', jwt, {
            name: name,
            description: description,
            categoryId: +typeId,
            countView: countView
        });

        if(response.statusSuccessful)
            onCreated(response.data);
        else alert(`Не получилось создать: ${response.error}`)
    }

    return <Modal show={show} onHide={onHide}>
        <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
            <Modal.Title>Создание категории</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3">
                <Form.Label>Название</Form.Label>
                <Form.Control
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Описание</Form.Label>
                <Form.Control 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Кол-во</Form.Label>
                <Form.Control 
                    required
                    value={countView}
                    onChange={(e) => setCountView(e.target.value)}
                    type="number"
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Тип</Form.Label>
                <Form.Select 
                    required
                    value={typeId}
                    onChange={(e) => setTypeId(e.target.value)}
                    >
                        {
                            types.map(o => <option key={o.id} value={o.id}>{o.name}</option>)
                        }
                    </Form.Select>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Отмена</Button>
            <Button variant="success" type="submit">Создать</Button>
        </Modal.Footer>
        </Form>
    </Modal>
}