import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { HttpData } from "../../../../Core";

export default function ProductModalCreate({ jwt, show, categories, onHide, onCreated }){

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [categoryId, setCategoryId] = useState(categories.length > 0 ? categories[0].id : -1);
    const [maxSize, setMaxSize] = useState('');
    const [minSize, setMinSize] = useState('');
    const [standardThickness, setStandardThickness] = useState('');
    const [standardWeight, setStandardWeight] = useState('');
    const [standardSize, setStandardSize] = useState('');

    const onSubmit = async(e) => {
        e.preventDefault();

        const response = await HttpData('/api/v1/calculateOrderCost/products', 'POST', jwt, {
            name: name,
            description: description,
            categoryId: +categoryId,
            maxSize: +maxSize,
            minSize: +minSize,
            standardThickness: +standardThickness,
            standardWeight: +standardWeight,
            standardSize: +standardSize
        });

        if(response.statusSuccessful)
            onCreated(response.data);
        else alert(`Не получилось создать: ${response.error}`)
    }

    return <Modal show={show} onHide={onHide}>
        <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
            <Modal.Title>Создание продукции</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3">
                <Form.Label>Название</Form.Label>
                <Form.Control
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Описание</Form.Label>
                <Form.Control 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Категория</Form.Label>
                <Form.Select 
                    required
                    value={categoryId}
                    onChange={(e) => setCategoryId(e.target.value)}
                    >
                        {
                            categories.map(o => <option key={o.id} value={o.id}>{o.name}</option>)
                        }
                    </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Максимальный размер</Form.Label>
                <Form.Control
                    required
                    type='number'
                    value={maxSize}
                    onChange={(e) => setMaxSize(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Минимальный размер</Form.Label>
                <Form.Control
                    required
                    type='number'
                    value={minSize}
                    onChange={(e) => setMinSize(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Стандартная толщина</Form.Label>
                <Form.Control
                    required
                    type='number'
                    value={standardThickness}
                    onChange={(e) => setStandardThickness(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Стандартный вес</Form.Label>
                <Form.Control
                    required
                    type='number'
                    value={standardWeight}
                    onChange={(e) => setStandardWeight(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Стандартный размер</Form.Label>
                <Form.Control
                    required
                    type='number'
                    value={standardSize}
                    onChange={(e) => setStandardSize(e.target.value)}
                    />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Отмена</Button>
            <Button variant="success" type="submit">Создать</Button>
        </Modal.Footer>
        </Form>
    </Modal>
}