import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import { createMaterial } from "../rawMaterialWarehouseApi";
import CreateWorkerExtraWorkView from "../createWorkerExtraWork";


export default function Create({ jwt, material, categories, workerExtraWorks, onCreated, show, onHide }) {

    const [localMaterial, setLocalMaterial] = useState({
        ...material,
        workerExtraWorkdId: workerExtraWorks.length > 0 ? workerExtraWorks[0].id : null
    });

    const [localExtraWorks, setLocalExtraWorks] = useState([...workerExtraWorks]);

    const [isCreateWorkerExtraWork, setIsCreateWorkerExtraWork] = useState(false);

    const submitMaterial = async(e) => {
        e.preventDefault();

        const response = await createMaterial({
            name: localMaterial.name,
            coefficient: localMaterial.coefficient,
            extraWorkId: localMaterial.workerExtraWorkdId,
            categoryId: localMaterial.categoryId
        });

        if(response && response.id > 0)
            onCreated(response);
        else alert('Что-то пошло не по плану, обратитесь в поддержку');
    }

    if(isCreateWorkerExtraWork)
        return <CreateWorkerExtraWorkView jwt={jwt}
                                        onCreated={(n) => {
                                            setLocalMaterial({ ...localMaterial, workerExtraWorkdId: n.id});
                                            setLocalExtraWorks([...localExtraWorks, n]);
                                            workerExtraWorks.push(n);
                                            setIsCreateWorkerExtraWork(false);
                                        }}
                                        onHide={() => setIsCreateWorkerExtraWork(false)}
                                        show={isCreateWorkerExtraWork}
                                        name={categories.find(c => c.id == localMaterial.categoryId).name + ' ' + localMaterial.name}/>

    return <Modal show={show} onHide={onHide} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
            <Form onSubmit={(e) => submitMaterial(e)}>
        <Modal.Header closeButton>
            <Modal.Title>Создание {localMaterial.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Расходные материалы</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Расходные материалы"
                        required
                        value={localMaterial.name}
                        onChange={(e) => setLocalMaterial({ ...localMaterial, name: e.target.value })}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                    <Form.Label>Коэффициент</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="1"
                        value={localMaterial.coefficient}
                        onChange={(e) => setLocalMaterial({ ...localMaterial, coefficient: e.target.value })}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                    <Form.Label>Категория</Form.Label>
                    <Form.Select aria-label="Default select categories" value={localMaterial.categoryId ?? -1} onChange={(e) => setLocalMaterial({ ...localMaterial, categoryId: e.target.value == -1 ? null : e.target.value })}>
                        {
                            categories.map(o => <option key={o.id} value={o.id ?? -1}>{o.name}</option>)
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                    <Form.Label>Работа</Form.Label>
                    <InputGroup>
                    <Form.Select aria-label="Default select worker Extra Work Id" value={localMaterial.workerExtraWorkdId ?? -1} onChange={(e) => setLocalMaterial({ ...localMaterial, workerExtraWorkdId: e.target.value == -1 ? null : e.target.value })}>
                        {
                            localExtraWorks.map(o => <option key={o.id} value={o.id ?? -1}>{o.name}</option>)
                        }
                    </Form.Select>
                    <Button onClick={() => setIsCreateWorkerExtraWork(true)}><TiPlus/></Button>
                    </InputGroup>
                </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Отмена</Button>
            <Button variant="success" type="submit">Сохранить</Button>
        </Modal.Footer>
            </Form>
    </Modal>
}