import { useEffect, useState } from "react";
import { Button, InputGroup, ListGroup, Modal } from "react-bootstrap";
import { HttpNoData } from "../../../../Core";
import { MdDeleteForever } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

export default function ModalNdsEdit({ show, onHide, jwt }) {

    const [elements, setElements] = useState([]);

    useEffect(() => {

        async function didMount() {
            
            const response = await HttpNoData('/api/v1/calculateOrderCost/nds', 'GET', jwt);

            setElements(response.data?.sort((a, b) => a.percent - b.percent) ?? [])
        }

        didMount();
    }, []);

    const deleteItem = async(id) => {

        const response = await HttpNoData(`/api/v1/calculateOrderCost/nds/${id}`, 'DELETE', jwt);

        if(!response.statusSuccessful)
        {
            alert('не получилось удалить!')
            return;
        }

        setElements([...elements.filter(f => f.id != id)]);
    }

    const createItem = async(percent) => {

        const response = await HttpNoData(`/api/v1/calculateOrderCost/nds/${percent}`, 'POST', jwt);

        if(!response.statusSuccessful)
        {
            alert("Не получилось создать");
            return;
        }

        setElements([...elements, response.data].sort((a, b) => a.percent - b.percent))
    }

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>НДС</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-grid gap-2">
            <ListGroup>
            {
                elements.map(map => <ListGroup.Item key={map.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h6>{map.date}</h6>
                        <InputGroup.Text><span>{ map.percent }%</span></InputGroup.Text>
                            <Button variant="danger" onClick={() => deleteItem(map.id)}>
                        <MdDeleteForever/>
                    </Button>
                    
                </ListGroup.Item>)
            }
            </ListGroup>
            <Button variant="success" onClick={() => {
                const percent = prompt('Укажите процент', 20);
                createItem(percent);
            }}>
                <FaPlus/>
            </Button>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Закрыть</Button>
        </Modal.Footer>
    </Modal>
}