import { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { deletePlace, updatePlace } from "../api";

export default function EditPlace({ show, onHide, jwt, onUpdate, onDeleted, places, place }) {

    const [placeLocal, setPlaceLocal] = useState({...place});
    const [onDelete, setOnDelete] = useState(null);

    const onSubmit = async() => {

        const response = await updatePlace(placeLocal.id, placeLocal.name, placeLocal.isMarketPlace, jwt);

        if(response)
            onUpdate(placeLocal);
        else alert('Не удалось создать, разрыв с сервером')
    }


    if(onDelete)
        return <Modal
        centered
        show={onDelete != null}
        onHide={() => setOnDelete(null)}
    >
        <Form onSubmit={e => e.preventDefault() || onSubmit()}>
            <Modal.Header closeButton>
                <Modal.Title>Удаление</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Что делать с отправками на данной платформе?</Form.Label>
                    <Form.Select
                        id='place-move-delete'
                        checked={onDelete.statusSends}
                        onChange={(e) => setOnDelete({...onDelete, statusSends: e.target.value})}
                    >
                        <option value={'delete'}>Удалить отправки</option>
                        <option value={'move'}>Переместить</option>
                    </Form.Select>
                </Form.Group>
                {
                    onDelete.statusSends == 'delete' ? <Alert variant="danger">Данное действие отразиться на статистике</Alert>
                    :  <Form.Group className="mb-3">
                    <Form.Label>Куда</Form.Label>
                    <Form.Select
                        required
                        id='place-move-new-place'
                        checked={onDelete.newPlace}
                        onChange={(e) => setOnDelete({...onDelete, newPlace: e.target.value})}
                    >
                       {
                         places && places.filter(f => f.id != place.id).length > 0 ?
                         places.filter(f => f.id != place.id).map(p => <option key={p.id} value={p.id}>{p.name}</option>) : ''
                       }
                    </Form.Select>
                </Form.Group>
                }
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOnDelete(null)}><span>Закрыть</span></Button>
                <Button variant="danger" onClick={async() => {
                    await deletePlace(place.id, onDelete.statusSends, onDelete.newPlace, jwt);
                     onDeleted();
                }}><span>Удалить</span></Button>
            </Modal.Footer>
        </Form>
    </Modal>

    return <Modal
        centered
        show={show}
        onHide={onHide}
    >
        <Form onSubmit={e => e.preventDefault() || onSubmit()}>
            <Modal.Header closeButton>
                <Modal.Title>{place.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={placeLocal.name}
                        onChange={(e) => setPlaceLocal({...placeLocal, name: e.target.value})}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check
                        type='checkbox'
                        id='is-marketplace-in-create-platform'
                        label='Маркетплейс'
                        checked={placeLocal.isMarketPlace}
                        onChange={(e) => setPlaceLocal({...placeLocal, isMarketPlace: e.target.checked})}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => setOnDelete({ statusSends: 'delete', newPlace: places.filter(f => f.id != place.id)[0].id})}><span>Удалить</span></Button>
                <Button variant="secondary" onClick={onHide}><span>Закрыть</span></Button>
                <Button variant="success" type="submit"><span>Сохранить</span></Button>
            </Modal.Footer>
        </Form>
    </Modal>
}
