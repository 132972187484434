import React from "react";
import { HttpNoData } from "../../Core";

export async function getMeAnalyze(date, jwt){

    const response = await HttpNoData(`/api/v1/shifts/month/${date}/analyze`, 'GET', jwt);

      if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}