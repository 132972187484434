import { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { deleteProduct, updateProduct } from "../api";

export default function EditProduct({ show, onHide, jwt, onUpdate, onDeleted, categories, product }) {

    const [productLocal, setProductLocal] = useState({...product});
    const [onDelete, setOnDelete] = useState(null);

    const onSubmit = async() => {

        const response = await updateProduct(productLocal.id, {
            name: productLocal.name,
            coefficient: productLocal.coefficient,
            categoryId: productLocal.categoryId
        }, jwt);

        if(response)
            onUpdate(productLocal);
        else alert('Не удалось создать, разрыв с сервером')
    }


    if(onDelete)
        return <Modal
        centered
        show={onDelete}
        onHide={() => setOnDelete(null)}
    >
        <Form onSubmit={e => e.preventDefault() || onSubmit()}>
            <Modal.Header closeButton>
                <Modal.Title>Удаление</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="danger">Данное действие отразиться на статистике, так же будут удалены все отправки по этой продукции</Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOnDelete(false)}><span>Закрыть</span></Button>
                <Button variant="danger" onClick={async() => {
                     await deleteProduct(product.id, jwt);
                      onDeleted()
                }}><span>Удалить</span></Button>
            </Modal.Footer>
        </Form>
    </Modal>

    return <Modal
        centered
        show={show}
        onHide={onHide}
    >
        <Form onSubmit={e => e.preventDefault() || onSubmit()}>
            <Modal.Header closeButton>
                <Modal.Title>{product.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={productLocal.name}
                        onChange={(e) => setProductLocal({...productLocal, name: e.target.value})}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Коэффициент</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        value={productLocal.coefficient}
                        onChange={(e) => setProductLocal({...productLocal, coefficient: e.target.value})}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Категория</Form.Label>
                    <Form.Select
                        required
                        value={productLocal.categoryId}
                        onChange={(e) => setProductLocal({...productLocal, categoryId: e.target.value})}
                    >
                        {
                            categories.map(c => <option key={c.id} value={c.id}>{c.name}</option>)
                        }
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => setOnDelete(true)}><span>Удалить</span></Button>
                <Button variant="secondary" onClick={onHide}><span>Закрыть</span></Button>
                <Button variant="success" type="submit"><span>Сохранить</span></Button>
            </Modal.Footer>
        </Form>
    </Modal>
}
