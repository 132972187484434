import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { HttpData, HttpNoData } from "../../../../Core";

export default function AdditionOrderModalEdit({ jwt, addition, show, onHide, onUpdated, onDeleted }){

    const [name, setName] = useState(addition.name);
    const [description, setDescription] = useState(addition.description);
    const [value, setValue] = useState(addition.value);
    const [coefficientValue, setCoefficientValue] = useState(addition.coefficientValue);
    const [math, setMath] = useState(addition.math);

    const onSubmit = async(e) => {
        e.preventDefault();

        const response = await HttpData(`/api/v1/calculateOrderCost/orders/additions/${addition.id}`, 'PUT', jwt, {
            name: name,
            description: description,
            value: +value,
            math: +math,
            coefficientValue: +coefficientValue
        });

        if(response.statusSuccessful)
            onUpdated({
                ...addition,
                name: name,
                description: description,
                value: +value,
                math: +math,
                coefficientValue: +coefficientValue
            });
        else alert(`Не получилось обновить: ${response.error}`)
    }

    const onDelete = async() => {

        const response = await HttpNoData(`/api/v1/calculateOrderCost/orders/additions/${addition.id}`, 'DELETE', jwt);

        if(response.statusSuccessful)
            onDeleted();
        else alert(`Не получилось удалить: ${response.error}`)
    }

    return <Modal show={show} onHide={onHide}>
        <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
            <Modal.Title>Обновление {addition.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3">
                <Form.Label>Название</Form.Label>
                <Form.Control 
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Описание</Form.Label>
                <Form.Control 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Значение</Form.Label>
                <Form.Control 
                    required
                    type="number"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Коэффициент</Form.Label>
                <Form.Control 
                    required
                    type="number"
                    value={coefficientValue}
                    onChange={(e) => setCoefficientValue(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Тип значения</Form.Label>
                <Form.Select 
                    value={math}
                    onChange={(e) => setMath(e.target.value)}
                    >
                        <option value={1}>Значение в ₽</option>
                        <option value={2}>Значение в %</option>
                        <option value={3}>Скидка в ₽</option>
                        <option value={4}>Скидка в %</option>
                    </Form.Select>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Отмена</Button>
            <Button variant="danger" disabled={!addition.isRightsDelete} onClick={onDelete}>Удалить</Button>
            <Button variant="success" type="submit">Сохранить</Button>
        </Modal.Footer>
        </Form>
    </Modal>
}