import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import {
	getSchedule,
	getWorkerCategories,
	getWorkers,
	getWorkStatuses,
	postWorkSchedule,
} from './API'

import { getCurrentDate, getDaysInMonth } from './utils'
import WorkScheduleTable from './Table/WorkScheduleTable'

const WorkSchedule = ({ jwt }) => {
	const mobileSize = 1200

	const [selectedDate, setSelectedDate] = useState(getCurrentDate());
	const [schedule, setSchedule] = useState();
	const [statuses, setStatuses] = useState([])
	const [workers, setWorkers] = useState([])
	const [workerCategories, setWorkerCategories] = useState([]);

	const [currentMonthDays, setCurrentMonthDays] = useState()
	const [formattedDate, setFormattedDate] = useState('');
	const [isMobile, setIsMobile] = useState(
		window.screen.width - mobileSize <= 0
	);

	const handleGetSchedule = async (date) => {
		setSchedule((await getSchedule(jwt, date)) ?? [])
	}

	useEffect(() => {
		async function DidMount() {
			setStatuses((await getWorkStatuses(jwt)) ?? []);
			setWorkers((await getWorkers(jwt)) ?? []);
			setWorkerCategories((await getWorkerCategories(jwt)) ?? [])

			setSelectedDate(selectedDate);
			setCurrentMonthDays(getDaysInMonth(selectedDate));
			await handleGetSchedule(selectedDate);
		}
		DidMount();
		window.addEventListener('resize', function (event) {
			setIsMobile(this.document.body.clientWidth - mobileSize <= 0)
		})
	}, [])

	const handleSchedulePost = async (date) => {
		await postWorkSchedule(jwt, { date: date })
		await handleGetSchedule(date);
	}

	useEffect(() => {

		if(!selectedDate)
			return;

		const dateParts = selectedDate.split('-')
		const month = dateParts[1]
		const year = dateParts[0]
		setCurrentMonthDays(getDaysInMonth(selectedDate))
		setFormattedDate(`${month}-${year}`)
	}, [selectedDate])

	const content =
		!schedule || schedule.length <= 0 ? (
			<>
				<h2>На данный месяц графика работ нет. Хотите создать?</h2>
				<Button onClick={() => handleSchedulePost(selectedDate)}>Создать график</Button>
			</>
		) : (
			<WorkScheduleTable
				jwt={jwt}
				selectedDate={selectedDate}
				schedule={schedule}
				workers={workers}
				statuses={statuses}
				isMobile={isMobile}
				currentMonthDays={currentMonthDays}
				workerCategories={workerCategories}
			/>
		)

	return (
		<div style={{ margin: '40px auto', maxWidth: '1440px' }}>
			<h1 style={{ textAlign: 'center' }}>График работы </h1>
			<Form>
				<Form.Label className='mb-3' htmlFor='month'>
					Выберите месяц
				</Form.Label>
				<Form.Control
					type='date'
					id='month'
					value={selectedDate}
					className='mb-3'
					onChange={e => {
						setSelectedDate(e.target.value)
						handleGetSchedule(e.target.value)
					}}
				/>
			</Form>
			<h1 style={{ textAlign: 'center' }}>{formattedDate}</h1>
			{content}
		</div>
	)
}

export default WorkSchedule
