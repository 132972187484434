import { Button, Form, ListGroup, Stack } from "react-bootstrap";
import { MdCheck, MdClose, MdDelete, MdEdit } from "react-icons/md";
import styles from './style.module.css';
import { useState } from "react";
import { HttpData, HttpNoData } from "../../../../Core";

export default function RangeSizeEdit({ rangeSize, onDeleted, jwt }) {

    const [isEdit, setIsEdit] = useState(false);

    const [size, setSize] = useState(rangeSize.size);
    const [price, setPrice] = useState(rangeSize.price);

    const updateAsync = async() => {

        const sizesResponse = await HttpData(`/api/v1/calculateOrderCost/productRangeSizes/${rangeSize.id}`, 'PUT', jwt, {
            price: +price,
            size: +size,
            productId: +rangeSize.productId
        });

        if(sizesResponse.statusSuccessful)
        {
            rangeSize.price = +price;
            rangeSize.size = +size;
            setIsEdit(false);
        }
        else alert('Не получилось обновить')
    }

    const deleteAsync = async() => {

        const sizesResponse = await HttpNoData(`/api/v1/calculateOrderCost/productRangeSizes/${rangeSize.id}`, 'DELETE', jwt);

        if(sizesResponse.statusSuccessful)
            onDeleted();
        else alert('Не получилось удалить')
    }

    if (isEdit)
        return <ListGroup.Item>
            <div className={styles.listItem}>
            <Stack direction="horizontal" gap={1}>
                <Form.Control
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                    type="number"
                />
                <Form.Control
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    type="number"
                />
                    <Button variant="success" onClick={updateAsync}>
                        <MdCheck />
                    </Button>
                    <Button variant="danger" onClick={() => setIsEdit(false)}>
                        <MdClose />
                    </Button>
                </Stack>
            </div>
        </ListGroup.Item>

    return <ListGroup.Item>
        <div className={styles.listItem}>
            <span>{rangeSize.size}мм</span>
            <span>{rangeSize.price}₽</span>
            <Stack direction="horizontal" gap={1}>
                <Button variant="warning" onClick={() => setIsEdit(true)}>
                    <MdEdit />
                </Button>
                <Button variant="danger" onClick={deleteAsync}>
                    <MdDelete />
                </Button>
            </Stack>
        </div>
    </ListGroup.Item>
};