import { HttpData, HttpNoData } from '../../../Core';

export async function getMaterials() {
    
    const response = await HttpNoData(`/api/v1/RawMaterialsWarehouse/materials`, 'GET');

    return response.data?.materials;
}

export async function getCategories(){

    const response = await HttpNoData(`/api/v1/RawMaterialsWarehouse/materials/categories`, 'GET');

    return response.data?.categories;
}

export async function createMaterial(body) {
    
    const response = await HttpData(`/api/v1/RawMaterialsWarehouse/materials`, 'POST', null, body);

    return response.data;
}

export async function updateMaterial(body) {
    
    const response = await HttpData(`/api/v1/RawMaterialsWarehouse/materials`, 'PUT', null, body);

    return response.statusSuccessful;
}

export async function deleteMaterial(id) {
    
    const response = await HttpNoData(`/api/v1/RawMaterialsWarehouse/materials/${id}`, 'DELETE', null);

    return response.statusSuccessful;
}

export async function createWorkerExtraWork(jwt, body) {
    
    const response = await HttpData(`/api/v1/ExtraWorks`, 'POST', jwt, body);

    return response.data;
}

export async function createCategory(body) {

    const response = await HttpData(`/api/v1/RawMaterialsWarehouse/materials/categories`, 'POST', null, body);

    return response.data;
}

export async function createPull(body) {
    
    const response = await HttpData(`/api/v1/RawMaterialsWarehouse/pulls`, 'POST', null, body);

    return response.data;
}

export async function getWarehouses(jwt) {
    
    const response = await HttpNoData(`/api/v1/RawMaterialsWarehouse/warehouses`, 'GET', jwt);

    return response.data?.warehouses;
}

export async function getAmounts(warehouseId) {
    
    const response = await HttpNoData(`/api/v1/RawMaterialsWarehouse/materials/warehouses/${warehouseId}/amounts`, 'GET');

    return response.data?.materials;
}

export async function moveSumOnWarehouses(body) {
    
    const response = await HttpNoData(`/api/v1/RawMaterialsWarehouse/pulls/move`, 'POST');

    return response.statusSuccessful;
}