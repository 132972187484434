import React, { useEffect, useState } from "react"
import { Form, InputGroup, OverlayTrigger, Table, Toast, ToastContainer, Tooltip } from "react-bootstrap";
import styles from './style.module.css';
import { HttpNoData } from "../../../Core";
import { HiMiniReceiptPercent } from "react-icons/hi2";
import { FaRubleSign } from "react-icons/fa";
import { IoResize } from "react-icons/io5";
import { RxHeight } from "react-icons/rx";
import { AiOutlineNumber } from "react-icons/ai";

export default function Product({ product, jwt, isUse, onSum, onWeight, onUpdate }) {

    // arrays
    const [currentAdditions, setCurrentAddition] = useState(product.additions ?? []);
    const [currentRangeSizes, setCurrentRangeSizes] = useState([]);
    const [currentRangeThickness, setCurrentRangeThickness] = useState([]);
    const [currentCountPrices, setCurrentCountPrices] = useState([]);

    // count property
    const [selfCount, setSelfCount] = useState(product.selfCount ?? 1);
    const [priceSelf, setPriceSelf] = useState(product.priceSelf ?? 0);

    // error toast
    const [errorToast, setErrorToast] = useState();

    // thickness, weight and size
    const [thickness, setThickness] = useState(product.thicknessUser ?? product.standardThickness);
    const [size, setSize] = useState(product.sizeUser ?? product.standardSize);
    const [weight, setWeight] = useState(0);

    // value or percent discount
    const [discountsPercent, setDiscountsPercent] = useState(product.discountsPercent ?? []);
    const [discountsValue, setDiscountsValue] = useState(product.discountsValue ?? []);

    // using in fake id key in list (map)
    const [percentId, setPercentId] = useState(product.discountsPercent && product.discountsPercent.length > 0 ? product.discountsPercent[product.discountsPercent.length - 1].id : 1);
    const [valueId, setValueId] = useState(product.discountsValue && product.discountsValue.length > 0 ? product.discountsValue[product.discountsValue.length - 1].id : 1);

    // prices
    const [sumSize, setSumSize] = useState(product.sumSize ?? 0);
    const [nominalPrice, setNominalPrice] = useState(product.nominalPrice ?? 0);
    const [sumAddition, setSumAddition] = useState(product.sumAddition ?? 0);
    const [priceEnd, setPriceEnd] = useState(product.priceEnd ?? 0);
    const [priceSumCount, setPriceSumCount] = useState(product.priceSumCount ?? 0);


    // did mount
    useEffect(() => {

        async function didMount() {
            const sizes = await HttpNoData(`/api/v1/calculateOrderCost/productRangeSizes/${product.id}`, 'GET', jwt);

            if (sizes.statusSuccessful)
                setCurrentRangeSizes(sizes.data?.sort((a, b) => a.size - b.size) ?? []);

            const thicknessResponse = await HttpNoData(`/api/v1/calculateOrderCost/productRangeThickness/${product.id}`, 'GET', jwt);

            if (thicknessResponse.statusSuccessful)
                setCurrentRangeThickness(thicknessResponse.data?.sort((a, b) => a.thickness - b.thickness) ?? []);

            const additions = await HttpNoData(`/api/v1/calculateOrderCost/products/additions/${product.id}`, 'GET', jwt);

            if (additions.statusSuccessful && additions.data)
                setCurrentAddition([...currentAdditions, ...additions.data?.filter(f => currentAdditions.findIndex(find => find.id == f.id) == -1)]);

            const countPrices = await HttpNoData(`/api/v1/calculateOrderCost/products/countPrices/${product.id}`, 'GET', jwt);

            if (additions.statusSuccessful)
                setCurrentCountPrices(countPrices.data?.sort((a, b) => a.count - b.count) ?? []);
        }

        didMount();

    }, []);

    // math price (sumSize)
    useEffect(() => {

        if (!Number(size)) {
            onSum(product, 0);
            return;
        }

        if (size < product.minSize || size > product.maxSize) {
            setErrorToast({ key: 'outRange', message: `К сожалению вы вышли за границы продукции. \n От ${product.minSize}мм до ${product.maxSize}мм`, variant: 'danger' });
            return;
        }

        if (errorToast && errorToast.key == 'outRange')
            setErrorToast();

        if (currentRangeSizes.length <= 1) {
            setSumSize(currentRangeSizes[0]?.price ?? 0);
            return;
        }

        for (let i = 0; i < currentRangeSizes.length; i++) {
            const element = currentRangeSizes[i];

            if (element.size < size && currentRangeSizes.length - (i + 1) > 0)
                continue;

            if (element.size == size) {
                setSumSize(element.price);
                break;
            }

            let minPrice = i == 0 ? 0 : currentRangeSizes[i - 1].price;
            let maxPrice = element.price;
            let minSize = i == 0 ? 0 : currentRangeSizes[i - 1].size;
            let maxSize = element.size;

            setSumSize((maxPrice - minPrice) / (maxSize - minSize) * (size - minSize) + minPrice);
            break;
        }

    }, [size, currentRangeSizes]);

    // nominal price
    useEffect(() => {

        if (product.standardThickness == thickness) {
            const nominalPrice = +(+sumSize).toFixed(0);
            setNominalPrice(nominalPrice);
            return;
        }

        let upperPriceOnThickness = 0;
        for (let i = 0; i < currentRangeThickness.length; i++) {
            const element = currentRangeThickness[i];

            if (element.thickness < thickness && currentRangeThickness.length - (i + 1) > 0)
                continue;

            upperPriceOnThickness = element.percent / 100;
            break;
        }

        const nominalPrice = +(+sumSize + (+sumSize * upperPriceOnThickness)).toFixed(0);
        setNominalPrice(nominalPrice);
    }, [thickness, sumSize]);

    // on update
    useEffect(() => {

        onUpdate({
            ...product,
            additions: currentAdditions.filter(f => f.isUse),
            isUse: isUse,
            thicknessUser: thickness,
            sizeUser: size,
            discountsPercent: discountsPercent,
            discountsValue: discountsValue,
            sumSize: sumSize,
            sumAddition: sumAddition,
            nominalPrice: nominalPrice,
            selfCount: selfCount,
            priceSelf: priceSelf,
            priceSumCount: priceSumCount,
            priceEnd: priceEnd
        });

    }, [thickness, size, priceSumCount, discountsPercent, discountsValue, sumSize, sumAddition, nominalPrice, selfCount, priceEnd]);

    // math weight
    useEffect(() => {
        const result = ((size * size) / (product.standardSize * product.standardSize) * (thickness / product.standardThickness) * product.standardWeight) * selfCount;
        setWeight(result);
        onWeight(product, result)
    }, [size, thickness, selfCount]);

    useEffect(() => {

        const additionPrice = +(+nominalPrice + +sumAddition).toFixed(0);

        let price = additionPrice;

        if (discountsValue.length > 0)
            price += discountsValue.reduce((a, b) => a + b.value, 0);

        if (discountsPercent.length > 0)
            price += discountsPercent.reduce((a, b) => (b.percent / 100) + a, 0) * additionPrice;

        setPriceEnd(price);
    }, [discountsPercent, discountsValue, nominalPrice, sumAddition]);

    useEffect(() => {

        let discount = 0;
        for (let i = 0; i < currentCountPrices.length; i++) {
            const element = currentCountPrices[i];

            if (element.count > selfCount)
                continue;

            discount = element.discount;
        }

        const priceSelf = priceEnd - discount;
        const price = priceSelf * selfCount;

        setPriceSumCount(price);
        setPriceSelf(priceSelf);
        onSum(product, price);

    }, [priceEnd, selfCount]);

    return <div className={styles.productItem}>
        <Table bordered hover striped size="sm" style={{ margin: 0, padding: 0 }}>
            <tbody>
                <tr>
                    <th style={{ width: 280, padding: 0 }}>
                        <InputGroup style={{ maxWidth: 280, flexWrap: 'nowrap' }}>
                            <OverlayTrigger overlay={<Tooltip id={`product-addition-label-1`}>Размер</Tooltip>}>
                                <InputGroup.Text>
                                    <IoResize />
                                </InputGroup.Text>
                            </OverlayTrigger>

                            <Form.Control
                                disabled={!isUse}
                                value={size}
                                onChange={e => setSize(e.target.value)}
                                type="number"
                                name="size-select"
                                placeholder=""
                                list={`datalist-sizes-product-${product.id}`}
                            />
                            <datalist id={`datalist-sizes-product-${product.id}`}>
                                {
                                    currentRangeSizes.map(si => <option key={si.id} value={si.size} />)
                                }
                            </datalist>

                            <OverlayTrigger overlay={<Tooltip id={`product-addition-label-2`}>Толщина</Tooltip>}>
                                <InputGroup.Text>
                                    <RxHeight />
                                </InputGroup.Text>
                            </OverlayTrigger>

                            <Form.Select
                                disabled={!isUse}
                                value={thickness}
                                placeholder=""
                                onChange={e => setThickness(e.target.value)}
                            >
                                <option value={product.standardThickness}>{product.standardThickness}</option>
                                {
                                    currentRangeThickness.map(item => <option key={item.id} value={item.thickness}>{item.thickness}</option>)
                                }
                            </Form.Select>
                        </InputGroup>
                    </th>
                    <th style={{ padding: 0  }}> 
                        <div className={styles.additionBodyProduct}>
                            {
                                currentAdditions && currentAdditions.length > 0 ?
                                    currentAdditions.map(o => <Form.Group className={styles.additionItem} key={o.id}>
                                        <OverlayTrigger overlay={<Tooltip id={`product-addition-tooltip-${o.id}`}>{o.description}</Tooltip>}>
                                            <Form.Check
                                                disabled={!isUse}
                                                type="switch"
                                                id={`product-${product.id}-addition-${o.id}`}
                                                style={{ marginLeft: 8, marginBottom: -6 }}
                                                label={<span id={`product-${product.id}-addition-${o.id}`}>{`${o.name} (${o.price}₽)`}</span>}
                                                checked={o.isUse}
                                                onChange={e => setSumAddition(e.target.checked ? sumAddition + o.price : sumAddition - o.price) || (o.isUse = e.target.checked)}
                                            />
                                        </OverlayTrigger>

                                    </Form.Group>)
                                    : ''
                            }
                        </div>
                    </th>
                    <th style={{ width: 120, padding: 0  }}>
                        <InputGroup style={{ maxWidth: 120, flexWrap: 'nowrap' }}>
                            <OverlayTrigger overlay={<Tooltip id={`product-addition-label-2`}>Кол-во</Tooltip>}>
                                <InputGroup.Text>
                                    <AiOutlineNumber />
                                </InputGroup.Text>
                            </OverlayTrigger>

                            <Form.Control
                                disabled={!isUse}
                                value={selfCount}
                                placeholder=""
                                onChange={e => {

                                    const value = e.target.value;

                                    if (!Number(value))
                                        return;

                                    setSelfCount(value);

                                    if (value <= 0)
                                        setErrorToast({ key: 'minZeroCount', message: `К сожалению кол-во продукции не может быть менее 1`, variant: 'danger' })
                                    else if (errorToast?.key == 'minZeroCount')
                                        setErrorToast();

                                    if (value % 1 > 0)
                                        setErrorToast({ key: 'numberPoint', message: `К сожалению кол-во продукции не может быть дробным`, variant: 'danger' })
                                    else if (errorToast?.key == 'numberPoint')
                                        setErrorToast();
                                }}
                            />
                        </InputGroup>
                    </th>
                    <th style={{ width: 90, padding: 0 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <HiMiniReceiptPercent onClick={() => {
                            const value = prompt('Скидка в процентах (Без знака %)', 10)
                            setDiscountsPercent([...discountsPercent, { id: percentId, percent: +value * -1 }]);
                            setPercentId(percentId + 1);
                        }} style={{ width: 18, height: 18, color: 'green' }} />
                        <FaRubleSign onClick={() => {
                            const value = prompt('Скидка в рублях', 10)
                            setDiscountsValue([...discountsValue, { id: valueId, value: +value * -1 }]);
                            setValueId(valueId + 1);
                        }} style={{ width: 18, height: 18, color: 'green' }} />
                        </div>
                        {
                            discountsPercent.map(p => <div key={p.id} className={styles.productPriceItem}>
                                <span className={styles.price_name} onClick={() => setDiscountsPercent([...discountsPercent.filter(f => f.id != p.id)])}>В %</span>
                                <span className={styles.price_separator} />
                                <span className={styles.price_value}>{p.percent}%</span>
                            </div>)
                        }

                        {
                            discountsValue.map(p => <div key={p.id} className={styles.productPriceItem}>
                                <span className={styles.price_name} onClick={() => setDiscountsValue([...discountsValue.filter(f => f.id != p.id)])}>В ₽</span>
                                <span className={styles.price_separator} />
                                <span className={styles.price_value}>{p.value}₽</span>
                            </div>)
                        }
                    </th>
                    <th style={{ width: 120, padding: 0 }}>
                        <div className={styles.productPriceItem}>
                            <span className={styles.price_name}>Номинал</span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>{nominalPrice}₽</span>
                        </div> <div className={styles.productPriceItem}>
                            <span className={styles.price_name}>Допики</span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>{sumAddition}₽</span>
                        </div>
                    </th>
                    <th style={{ width: 120, padding: 0 }}>
                        <div className={styles.productPriceItem}>
                            <span className={styles.price_name}>₽ за шт.</span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>{priceSelf}₽</span>
                        </div><div className={styles.productPriceItem}>
                            <span className={styles.price_name}>Сумма</span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>{priceSumCount}₽</span>
                        </div>
                    </th>
                    <th style={{ width: 100, padding: 0 }}>
                        <div className={styles.productPriceItem}>
                            <span className={styles.price_name}>Вес</span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>{+(+weight / 1000).toFixed(3)}кг</span>
                        </div>
                    </th>
                </tr>
            </tbody>
        </Table>
        {
            errorToast ?
                <ToastContainer
                    style={{
                        position: 'fixed',
                        zIndex: 1,
                        right: 20,
                        bottom: 20
                    }}
                >
                    <Toast
                        className="d-inline-block m-1"
                        bg={errorToast.variant}
                        onClose={() => setErrorToast()}
                    >
                        <Toast.Header>
                            <strong className="me-auto">Внимание!</strong>
                        </Toast.Header>
                        <Toast.Body className={'text-white'}>
                            {errorToast.message}
                        </Toast.Body>
                    </Toast>
                </ToastContainer> : ''
        }
    </div>
}