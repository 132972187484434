

export const notificationPriorityList = [
	{
		id: 0,
		name: 'информационное',
	},
	{
		id: 1,
		name: 'напоминающее',
	},
	{
		id: 2,
		name: 'предупредительное',
	},
]
