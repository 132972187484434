import { Button, Form, ListGroup, Stack } from "react-bootstrap";
import { MdCheck, MdClose, MdDelete, MdEdit } from "react-icons/md";
import styles from './style.module.css';
import { useState } from "react";
import { HttpData, HttpNoData } from "../../../../Core";

export default function RangePriceOnCount({ range, onDeleted, jwt }) {

    const [isEdit, setIsEdit] = useState(false);

    const [count, setCount] = useState(range.count);
    const [discount, setDiscount] = useState(range.discount);

    const updateAsync = async() => {

        const sizesResponse = await HttpData(`/api/v1/calculateOrderCost/products/countPrices/${range.id}`, 'PUT', jwt, {
            count: +count,
            discount: +discount,
            productId: +range.productId
        });

        if(sizesResponse.statusSuccessful)
        {
            range.discount = +discount;
            range.count = +count;
            setIsEdit(false);
        }
        else alert('Не получилось обновить')
    }

    const deleteAsync = async() => {

        const sizesResponse = await HttpNoData(`/api/v1/calculateOrderCost/products/countPrices/${range.id}`, 'DELETE', jwt);

        if(sizesResponse.statusSuccessful)
            onDeleted();
        else alert('Не получилось удалить')
    }

    if (isEdit)
        return <ListGroup.Item>
            <div className={styles.listItem}>
            <Stack direction="horizontal" gap={1}>
                <Form.Control
                    value={count}
                    onChange={(e) => setCount(e.target.value)}
                    type="number"
                />
                <Form.Control
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    type="number"
                />
                    <Button variant="success" onClick={updateAsync}>
                        <MdCheck />
                    </Button>
                    <Button variant="danger" onClick={() => setIsEdit(false)}>
                        <MdClose />
                    </Button>
                </Stack>
            </div>
        </ListGroup.Item>

    return <ListGroup.Item>
        <div className={styles.listItem}>
            <span>{range.count}шт.</span>
            <span>-{range.discount}₽</span>
            <Stack direction="horizontal" gap={1}>
                <Button variant="warning" onClick={() => setIsEdit(true)}>
                    <MdEdit />
                </Button>
                <Button variant="danger" onClick={deleteAsync}>
                    <MdDelete />
                </Button>
            </Stack>
        </div>
    </ListGroup.Item>
};