import React, { useEffect, useState } from "react";
import { Accordion, Alert, Badge, Button, Form, Stack } from "react-bootstrap";
import styles from './style.module.css';
import { TiPlus } from "react-icons/ti";
import EditMaterial from "./RawMaterialCRUD/edit";
import CreateMaterial from "./RawMaterialCRUD/create";
import CreateCategory from "./CategoryCRUD/create";
import { getExtraWorks } from "../../../apiMethod/extraWorks";
import CreatePull from "./RawMaterialCRUD/createPull";
import { deleteMaterial, getAmounts, getCategories, getMaterials, getWarehouses } from "./rawMaterialWarehouseApi";
import MoveSum from "./RawMaterialCRUD/moveSum";

function Main({ jwt }) {

    const [warehouses, setWarehouses] = useState([]);
    const [currentWarehouseId, setCurrentWarehouseId] = useState(null);

    const [materials, setMaterials] = useState([]);
    const [categories, setCategories] = useState([]);

    const [isCreateMaterial, setIsCreateMaterial] = useState(false);
    const [isCreateCategory, setIsCreateCategory] = useState(false);

    const [currentCategoryId, setCurrentCategoryId] = useState(null);

    const [workerExtraWorks, setWorkerExtraWorks] = useState([]);


    useEffect(() => {

        async function didMount() {

            const responseWarehouses = (await getWarehouses()) ?? [];
            const responseMaterials = (await getMaterials()) ?? [];
            const responseCategories = (await getCategories()) ?? [];
            const responseExtraWorks = (await getExtraWorks(jwt)) ?? [];

            setCategories([...responseCategories, {
                "id": null,
                "name": "Без категории"
            }]);

            setMaterials(responseMaterials.map(o => {
                o.sum = 0;
                return o;
            }));

            setWarehouses(responseWarehouses);
            setWorkerExtraWorks(responseExtraWorks);

            if (responseWarehouses && responseWarehouses.length > 0)
                setCurrentWarehouseId(responseWarehouses[0].id);
        }

        didMount();

    }, []);

    useEffect(() => {

        if(!currentWarehouseId)
            return;

        async function didMount() {
            const responseAmounts = await getAmounts(currentWarehouseId);

            setMaterials(materials.map(o => {
                o.sum = responseAmounts.find(f => f.materialId == o.id).sum;
                return o;
            }));
        }

        didMount();

    }, [currentWarehouseId]);


    const deleteMaterialPreview = async (id) => {

        if (!window.confirm("Вы точно хотите удалить?"))
            return;

        if (!await deleteMaterial(id)) {
            alert('К сожалению не получилось удалить');
            return;
        }

        setMaterials([...materials.filter(o => o.id != id)]);
    }

    const MaterialItemView = ({ material }) => {

        const [isEdit, setIsEdit] = useState(false);
        const [isPull, setIsPull] = useState(false);
        const [isMove, setIsMove] = useState(false);

        return <React.Fragment>
            <Accordion.Item eventKey={material.id}>
                <Accordion.Header>
                    <div className={styles.accordionHeader}>
                        {material.name}
                        <div>
                            <Badge className={styles.badge}>Коефф: {material.coefficient}</Badge>
                            <Badge className={styles.badge}>Кол-во: {material.sum}</Badge>
                        </div>

                    </div>
                </Accordion.Header>
                <Accordion.Body className={styles.buttonsEvent}>
                    <Button className={styles.buttonEvent} onClick={() => setIsPull(true)} variant="warning">Кол-во</Button>
                    <Button className={styles.buttonEvent} onClick={() => setIsMove(true)} variant="warning">Перевести</Button>
                    <Button className={styles.buttonEvent} onClick={() => setIsEdit(true)} variant="warning">Изменить</Button>
                    <Button className={styles.buttonEvent} onClick={() => deleteMaterialPreview(material.id)} variant="danger">Удалить</Button>
                </Accordion.Body>
            </Accordion.Item>
            {
                isEdit ? <EditMaterial material={material}
                    jwt={jwt}
                    workerExtraWorks={workerExtraWorks}
                    categories={categories}
                    onUpdate={(u) => setMaterials([...materials.filter(o => o.id != u.id), u]) || setIsEdit(false)}
                    show={isEdit}
                    onHide={() => setIsEdit(false)} /> : ''
            }
            {
                isPull ? <CreatePull id={material.id}
                    currentWarehouseId={currentWarehouseId}
                    show={isPull}
                    onHide={() => setIsPull(false)}
                    onUpdate={(c) => setMaterials([...materials.filter(o => o.id != material.id), { ...material, sum: +material.sum + +c }]) || setIsPull(false)} /> : ''
            }
            {
                isMove ? <MoveSum
                currentWarehouseId={currentWarehouseId}
                materialId={material.id}
                onHide={() => setIsMove(false)}
                onMoved={(v) => setMaterials([...materials.filter(o => o.id != material.id), { ...material, sum: +material.sum - +v }]) || setIsPull(false)}
                show={isMove}
                warehouses={warehouses}
                />: ''
            }
        </React.Fragment>
    };

    const CategoryItemView = ({ category }) => {

        const onClick = () => {
            setCurrentCategoryId(category.id);
        }

        return <Button onClick={onClick} variant={currentCategoryId == category.id ? "outline-primary" : "primary"} className={styles.buttonCategory}>{category.name}</Button>
    }

    const materialsView = materials && materials.length > 0 ? <div>
        <Accordion>{materials.filter(o => o.categoryId == currentCategoryId).map(o => <MaterialItemView key={o.id} material={o} />)}</Accordion>
    </div> :
        <Alert variant="warning">К сожалению сейчас нет продукции</Alert>;

    const categoriesView = categories && categories.length > 0 ? <div className={styles.categories}>
        {categories.map(o => <CategoryItemView key={o.id} category={o} />)}
    </div> :
        <Alert variant="warning">К сожалению сейчас нет категорий</Alert>;

    return <div className={styles.container}>
        <div className={styles.content}>
        <Form>
                    <Form.Label>Склад</Form.Label>
                    <Form.Select value={currentWarehouseId ?? -1} onChange={(e) => setCurrentWarehouseId(e.target.value)}>
                        {
                            warehouses && warehouses.length > 0 ?
                                warehouses.map(w => <option key={w.id} value={w.id}>{w.name}</option>) :
                                <option>Не выбран</option>
                        }
                    </Form.Select>
                </Form>
            <Stack className={styles.buttonsUpper} direction="horizontal" gap={3}>
                <Button onClick={() => setIsCreateMaterial(true)} variant="success"><TiPlus /> продукцию</Button>
                <Button onClick={() => setIsCreateCategory(true)} variant="success"><TiPlus /> категорию</Button>
            </Stack>

            <Stack gap={1}>
                {categoriesView}
                {materialsView}
            </Stack>
        </div>
        {
            isCreateMaterial ?
                <CreateMaterial material={{
                    "name": '',
                    "categoryId": null,
                    "workerExtraWorkdId": null,
                    "managerMaterialId": null,
                    "coefficient": 1,
                }}
                    jwt={jwt}
                    categories={categories}
                    onHide={() => setIsCreateMaterial(false)}
                    onCreated={(n) => setMaterials([...materials, { ...n, sum: 0 }]) || setIsCreateMaterial(false)}
                    show={isCreateMaterial}
                    workerExtraWorks={workerExtraWorks} /> : ''
        }
        {
            isCreateCategory ?
                <CreateCategory show={isCreateCategory} onHide={() => setIsCreateCategory(false)}
                    onCreated={(c) => setCategories([...categories, c]) || setIsCreateCategory(false)} /> : ''
        }
    </div>
}

export default Main;