import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default function ExtraWorkAdd({ show, onHide, extraWorks, categories }){

    const [currentCategoryId, setCurrentCategoryId] = useState(categories.length > 0 ? categories[0].id : 0);
    const [currentExtraWorkId, setCurrentExtraWorkId] = useState(-1);

    const [currentExtraWorks, setCurrentExtraWorks] = useState([]);

    const [value, setValue] = useState('');
    const [comment, setComment] = useState('');

    useEffect(() => {
        setCurrentExtraWorks(extraWorks.filter(f => f.categoryId == currentCategoryId));
        setCurrentExtraWorkId(-1);
    }, [currentCategoryId]);

    const onSubmit = (e) => {
        e.preventDefault();
        onHide();
    }

    return <Modal show={show} onHide={onHide}>
        <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
            <Modal.Title>Добавление работы</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3">
                <Form.Label>Категория</Form.Label>
                <Form.Select
                onChange={e => setCurrentCategoryId(e.target.value)}
                value={currentCategoryId}
                required>
                    {
                        categories.map(map => <option value={map.id} key={map.id}>{map.name}</option>)
                    }
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Работа</Form.Label>
                <Form.Select
                onChange={e => setCurrentExtraWorkId(e.target.value)}
                value={currentExtraWorkId}
                required>
                    {
                        currentExtraWorks.map(map => <option value={map.id} key={map.id}>{map.name}</option>)
                    }
                    {
                        currentExtraWorks.length > 0 && currentExtraWorks.findIndex(f => f.id == currentExtraWorkId) == -1 ?
                        <option value={-1}>Не выбрано</option> : ''
                    }
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Значение</Form.Label>
                <Form.Control type="number"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    required/>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Комментарий</Form.Label>
                <Form.Control type="text"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    as={'textarea'}/>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Закрыть</Button>
            <Button variant="success" type="submit">Создать</Button>
        </Modal.Footer>
        </Form>
    </Modal>
}