import { useEffect, useState } from "react";
import { Alert, Button, Form, ListGroup, Modal, Stack } from "react-bootstrap";
import styles from './dragDrop.module.css';
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import { updateProductCategory } from "../api";

export default function DragDropCategories({ show, onHide, jwt, onUpdate, categories }) {

    const [categoriesLocal, setCategoriesLocal] = useState([...categories.map(o => ({ ...o }))]);
    const [isDuplicate, setIsDuplicate] = useState(false);

    useEffect(() => {
    
        const duplicates = [];
        for (let i = 0; i < categories.length; i++) {
            const index = duplicates.findIndex(f => f == categories[i].priority);

            if(index == -1)
            {
                duplicates.push(categories[i].priority);
                continue;
            } else {
                setIsDuplicate(true);
                break;
            }
        }
    }, []);

    const arrayFixPriority = (array) => {

        const temp = [...array.sort((a, b) => a.priority - b.priority)];

        setIsDuplicate(false);
        return temp.map((o, i) => {
            o.priority = i + 1;
            return o;
        });
    }

    const arrayMove = (array, direction, index) => {

        const temp = [...array];
        if (direction == 'up') {
            const oldP = temp[index].priority;
            const newP = temp[index - 1].priority;
            temp[index].priority = newP;
            temp[index - 1].priority = oldP;

        } else if ('down') {
            const oldP = temp[index].priority;
            const newP = temp[index + 1].priority;
            temp[index].priority = newP;
            temp[index + 1].priority = oldP;
        }
        return temp;
    }

    const onSubmit = async() => {

        const update = categoriesLocal.filter(f => categories.find(c => c.id == f.id).priority != f.priority);

        const promises = [];
        for (let i = 0; i < update.length; i++) {
            promises[i] = updateProductCategory(update[i].id, update[i].name, update[i].priority, jwt);
        }

        for (let i = 0; i < promises.length; i++) {
            await promises[i];
        }

        onUpdate(categoriesLocal);
    }

    return <Modal
        centered
        show={show}
        onHide={onHide}
    >
        <Form onSubmit={e => e.preventDefault() || onSubmit()}>
            <Modal.Header closeButton>
                <Modal.Title>Сортировка</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {
                        categoriesLocal && categoriesLocal.length > 0 ?
                            categoriesLocal.sort((a, b) => a.priority - b.priority).map((c, i) => <ListGroup.Item
                                variant={c.priority == categories.find(f => f.id == c.id).priority ? 'success' : 'warning'}
                                className={styles.category}
                                key={c.id}>
                                <Stack direction="horizontal">
                                    <span>{c.name}</span>
                                    <Stack className="ms-auto" gap={1} direction="horizontal">
                                        <Button
                                            variant="success"
                                            disabled={i == 0}
                                            onClick={() => setCategoriesLocal(arrayMove(categoriesLocal, 'up', i))}>
                                            <BiUpArrow />
                                        </Button>
                                        <Button
                                            variant="danger"
                                            disabled={i == categoriesLocal.length - 1}
                                            onClick={() => setCategoriesLocal(arrayMove(categoriesLocal, 'down', i))}>
                                            <BiDownArrow />
                                        </Button>
                                    </Stack>
                                </Stack>
                            </ListGroup.Item>) : ''
                    }
                </ListGroup>
                {
                    isDuplicate ?
                    <Stack gap={1} direction="horizontal" className="mt-3">
                    <Alert variant="warning" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <span>Есть схожести в приоритетах</span>
                        <Button className="ms-auto" variant="warning" onClick={() => setCategoriesLocal(arrayFixPriority(categoriesLocal))}>Исправить</Button>
                    </Alert>
                </Stack> :''
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}><span>Закрыть</span></Button>
                <Button variant="success" type="submit"><span>Сохранить</span></Button>
            </Modal.Footer>
        </Form>
    </Modal>
}
