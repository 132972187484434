import React, { useEffect, useState } from "react";
import { HttpData, HttpNoData } from '../../../Core.js';
import styles from '../Style/startShift.module.css';
import { Alert } from "react-bootstrap";

function StartShift() {

    const [data, setData] = useState();
    const [machinesSeleted, setMachinesSelected] = useState([]);
    const [notification, setNotification] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const [jwt, setJwt] = useState(localStorage.getItem('jwtToken'));
    
    const getDataOnStart = async () => {
        
        var response = await HttpNoData("/api/v0/shifts/current/ValidateDataOnStartShift", "GET", jwt);
        const responseNotification = await HttpNoData(`/api/v1/notification/${'startShift'}`, 'GET', jwt);
        
        if(responseNotification.statusSuccessful)
            setNotification(responseNotification.data);

        if (response.statusSuccessful) {
            setData(response.data);
            setDisabledButton(false);
        }
        else {
            console.error(response.error);
        }
    }
    
    useEffect(() => { getDataOnStart(); }, []);

    const ClickStart = async () => {

        var response = await HttpData('/api/v0/shifts/current', 'POST', jwt, machinesSeleted);

        if (response.statusSuccessful) {
            window.location.href = "/Employee/TimeSpan/verificationCode?status=open";
        }
        else {
            alert("Данные не актуальны, обновите страницу");
            console.log(response.error);
        }
    }


    const UpdateMachine = (machine) => {

        const updatedItem = machine;
        updatedItem.isCheck = !updatedItem.isCheck;
        
        var indexEdit = machinesSeleted.findIndex(o => o.machineType == updatedItem.machineType);

        if (indexEdit == -1)
        setMachinesSelected([...machinesSeleted, updatedItem]);
        else setMachinesSelected([...machinesSeleted.filter(o => o.machineType == updatedItem.machineType)]);
    }

    const getVariant = (number) => {

        if(number == 0)
            return 'info';

        if(number == 1)
            return 'warning';

        if(number == 2)
            return 'danger';

        return 'warning';
    }

    if (data == null)
        return (<h5>SERVER LOAD</h5>);

    const inputFields = data.currentMachineShifts.map((machine, index) =>
        <div key={index} className={machine.isBlock ? styles.machine_item_disable : styles.machine_item}>
            <input className={styles.ceckBoxInput} type="checkbox" defaultChecked={machine.isCheck} onChange={() => { UpdateMachine(machine);}} disabled={machine.isBlock} />
            <span>{machine.machineType} ({machine.blockMessage})</span>
        </div>
    );

    return (
        <div className={styles.beta_body}>
            <div id="containerMenu" className={styles.menu}>

                <label className={styles.titleLabel}>Данные</label>
                <div id="containerData" className={styles.fieldData}>
                    <div>
                        <p className={styles.fieldData}>Сотрудник: {data.worker}</p>
                        <p className={styles.fieldData}>Смена: {data.workingShift}</p>
                        <p className={styles.fieldData}>Выходной: {data.isDayOff ? 'Да': 'Нет'}</p>
                    </div>
                </div>

                <label className={styles.titleLabel}>Параметры</label>
                <div id="containerInput" className={styles.fieldInput}>
                    {inputFields}
                </div>
                {
                    notification && notification.length > 0 ?
                    notification.map(n => <Alert key={n.id} variant={getVariant(n.priority)}>{n.message}</Alert>): ''
                }
                <button id="buttonStart" className={styles.buttonStart} onClick={ClickStart} disabled={disabledButton}>Начать смену</button>
            </div>
        </div>
    );
}

export default StartShift;