import React, { useEffect, useState } from 'react'
import { Container, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap'
import { CgProfile } from 'react-icons/cg'
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import styles from '../../App.module.css'
import { Exit } from '../../Core.js'
import { getAccount } from '../../apiMethod/Accounts/profile.js'
import AdministratorNavBar from '.././Administrator/Navbar.js'
import AnalyzeNavBar from '.././Analytic/Navbar.js'
import EmployeeNavBar from '.././Employee/navbar.js'
import Feedback from '.././Main/Feedback.js'
import HomePage from '.././Main/Home.js'
import IconLoad from '.././Main/IconLoad.js'
import LoginPage from '.././Main/LoginPage.js'
import ManagerNavBar from '.././Manager/navbar.js'
import Profile from '.././Profile/Profile.js'
import { linksAdministratorAll, linksAdministratorCode, linksEmployee, linksManager, linksAnalyze } from './NavBarExtensions.jsx'

export default function NavBar() {
    const expand = false
    const [state, setState] = useState('')
    const [user, setUser] = useState();
    const [specialization, setSpecialization] = useState('/')
    const [jwt, setJwt] = useState(localStorage.getItem('jwtToken'));

    const updateJwt = value => {
        localStorage.setItem('jwtToken', value)
        setJwt(value)
    }

    const getUser = async jwt => {
        const account = await getAccount(jwt)
        if (account)
            localStorage.setItem(
                'user',
                JSON.stringify({ timeUpdate: new Date(), data: account })
            )
        else setState(null)

        return account
    }

    useEffect(() => {
        async function load() {
            setUser(await getUser(jwt))
        }

        const spec = localStorage.getItem('specialization');
        setSpecialization(spec);

        const userLocal = JSON.parse(localStorage.getItem('user'));

        if (userLocal && new Date(userLocal.timeUpdate).getDay() == new Date().getDay() && userLocal.data) {
            setUser(userLocal.data)
            setState(null)
        } else load()
    }, [])

    const Links = specialization => {
        if (specialization.includes('/'))
            specialization = '/' + specialization.split('/')[1]

        switch (specialization) {

            case '/administrator':
                localStorage.setItem('specialization', specialization)

                if (user.administrator.root == 'all')
                    return linksAdministratorAll;

                if (user.administrator.root == 'code')
                    return linksAdministratorCode;

            case '/employee':
                localStorage.setItem('specialization', specialization)
                return linksEmployee;

            case '/manager':
                localStorage.setItem('specialization', specialization)
                return linksManager;

            case '/analyze':
                localStorage.setItem('specialization', specialization)
                return linksAnalyze;

            default:

                if (user.administrator) {
                    localStorage.setItem('specialization', '/administrator')
                    if (user.administrator.root == 'all')
                        return linksAdministratorAll;

                    if (user.administrator.root == 'code')
                        return linksAdministratorCode;
                }

                if (user.worker) {
                    localStorage.setItem('specialization', '/employee')
                    return linksEmployee;
                }

                if (user.manager) {
                    localStorage.setItem('specialization', '/manager')
                    return linksManager;
                }

                if (user.analytic) {
                    localStorage.setItem('specialization', '/analyze')
                    return linksAnalyze;
                }

                return [];

        }
    }

    const NavBarEG = ({ isСurtains, links, contentBodyCanvas, routesOnLinks, titleToggle, }) => {

        const [isVisible, setIsVisible] = useState(false);

        const LinkOnToggle = ({ path, Icon, text }) => {
            return (
                <Link
                    className={styles.nav_link_toggle}
                    onClick={() => setIsVisible(false)}
                    to={path}
                >
                    <Icon className={styles.nav_link_icon} />
                    <div className={styles.nav_link_text}>{text}</div>
                </Link>
            )
        }

        const LinkOnTop = ({ path, Icon, text }) => {
            return (
                <Link className={styles.nav_link_top} to={path}>
                    <Icon className={styles.nav_link_icon} />
                    <div className={styles.nav_link_text}>{text}</div>
                </Link>
            )
        }

        let pathname = window.location.pathname;
        let currentLink = links.find(f => f.path.includes(pathname));

        while (!currentLink) {
            pathname = pathname.slice(0, -1)
            currentLink = links.find(f => f.path.includes(pathname));
        }

        return (
            <Router>
                <Navbar
                    key={expand}
                    expanded={isVisible}
                    onToggle={() => setIsVisible(!isVisible)}
                    expand={isСurtains}
                    className='bg-body-tertiary'
                >
                    <Container fluid>
                        <Navbar.Brand href='/'>EG GROUP</Navbar.Brand>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {
                                currentLink.additions && currentLink.additions.length > 0 ?
                                    currentLink.additions.map((o, index) => <LinkOnToggle
                                        key={index}
                                        Icon={o.icon}
                                        path={o.path}
                                        text={o.text}
                                    />) : ''
                            }
                        </div>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement='end'
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    {titleToggle}
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className='justify-content-end flex-grow-1 pe-3'>
                                    {
                                        links ? links.map((o, index) =>
                                            o.isToggle ? (
                                                <LinkOnToggle
                                                    key={index}
                                                    Icon={o.icon}
                                                    path={o.path}
                                                    text={o.text}
                                                />
                                            ) : (
                                                <LinkOnTop
                                                    key={index}
                                                    Icon={o.icon}
                                                    path={o.path}
                                                    text={o.text}
                                                />
                                            )
                                        )
                                            : null}
                                    {contentBodyCanvas}
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
                {routesOnLinks}
            </Router>
        )
    }

    if (user)
        return (
            <NavBarEG
                isСurtains={false}
                titleToggle={user.name}
                contentBodyCanvas={
                    <NavDropdown
                        title='Сменить специальность'
                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                    >
                        {user.administrator === null ? null : (
                            <NavDropdown.Item href='/Administrator' onClick={() => localStorage.setItem('specialization', '/administrator')}>
                                Администратор
                            </NavDropdown.Item>
                        )}
                        {user.worker === null ? null : (
                            <NavDropdown.Item href='/Employee' onClick={() => localStorage.setItem('specialization', '/employee')}>
                                Производственный специалист
                            </NavDropdown.Item>
                        )}
                        {user.manager === null ? null : (
                            <NavDropdown.Item href='/Manager' onClick={() => localStorage.setItem('specialization', '/manager')}>Менеджер</NavDropdown.Item>
                        )}
                        {user.analytic === null ? null : (
                            <NavDropdown.Item href='/Analyze' onClick={() => localStorage.setItem('specialization', '/analyze')}>Аналитик</NavDropdown.Item>
                        )}
                        <NavDropdown.Divider />
                        <NavDropdown.Item href='/profile'>
                            Редактировать профиль
                        </NavDropdown.Item>
                        <NavDropdown.Item href='/feedback'>
                            Предложения по улучшению
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={Exit}>Выход</NavDropdown.Item>
                    </NavDropdown>
                }
                links={Links(specialization)}
                routesOnLinks={
                    <div>
                        <Routes>
                            <Route path='/*' element={<HomePage jwt={jwt} />} />
                            <Route path='/Login' element={<LoginPage />} />
                            <Route
                                path='/profile'
                                element={
                                    <Profile
                                        user={user}
                                        jwt={jwt}
                                        updateUser={getUser}
                                        updateJwt={updateJwt}
                                    />
                                }
                            />
                            <Route path='/feedback' element={<Feedback jwt={jwt} />} />
                            {user.analytic === null ? null : (
                                <Route
                                    path='/Analyze/*'
                                    element={<AnalyzeNavBar jwt={jwt} />}
                                />
                            )}
                            {user.worker === null ? null : (
                                <Route
                                    path='/Employee/*'
                                    element={<EmployeeNavBar jwt={jwt} />}
                                />
                            )}
                            {user.manager === null ? null : (
                                <Route
                                    path='/Manager/*'
                                    element={<ManagerNavBar jwt={jwt} />}
                                />
                            )}
                            {user.administrator === null ? null : (
                                <Route
                                    path='/Administrator/*'
                                    element={<AdministratorNavBar jwt={jwt} />}
                                />
                            )}
                        </Routes>
                    </div>
                }
            />
        )

    if (state == '')
        return (
            <div className={styles.load}>
                <IconLoad />
            </div>
        )
    return (
        <NavBarEG
            links={[
                { isToggle: false, icon: CgProfile, path: '/login', text: 'Вход' },
            ]}
            routesOnLinks={
                <div>
                    <Routes>
                        <Route path='/*' element={<HomePage />} />
                        <Route path='/Login' element={<LoginPage />} />
                    </Routes>
                </div>
            }
            isСurtains={true}
        />
    )
}
