import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { HttpData, HttpNoData } from "../../../../Core";

export default function CategoryModalEdit({ jwt, category, types, show, onHide, onUpdated, onDeleted }){

    const [name, setName] = useState(category.name);
    const [description, setDescription] = useState(category.description);
    const [typeId, setTypeId] = useState(category.categoryId);
    const [countView, setCountView] = useState(category.countView);

    const onSubmit = async(e) => {
        e.preventDefault();

        const response = await HttpData(`/api/v1/calculateOrderCost/productCategories/${category.id}`, 'PUT', jwt, {
            name: name,
            description: description,
            categoryId: +typeId,
            countView: countView
        });

        if(response.statusSuccessful)
            onUpdated({
                ...category,
                name: name,
                description: description,
                categoryId: +typeId,
                countView: countView
            });
        else alert(`Не получилось обновить: ${response.error}`)
    }

    const onDelete = async() => {

        const response = await HttpNoData(`/api/v1/calculateOrderCost/productCategories/${category.id}`, 'DELETE', jwt);

        if(response.statusSuccessful)
            onDeleted();
        else alert(`Не получилось удалить: ${response.error}`)
    }

    return <Modal show={show} onHide={onHide}>
        <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
            <Modal.Title>Обновление {category.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3">
                <Form.Label>Название</Form.Label>
                <Form.Control 
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Описание</Form.Label>
                <Form.Control 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Кол-во</Form.Label>
                <Form.Control 
                    required
                    value={countView}
                    onChange={(e) => setCountView(e.target.value)}
                    type="number"
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Тип</Form.Label>
                <Form.Select 
                    required
                    value={typeId}
                    onChange={(e) => setTypeId(e.target.value)}
                    >
                        {
                            types.map(o => <option key={o.id} value={o.id}>{o.name}</option>)
                        }{
                            types.findIndex(f => f.id == category.categoryId) == -1 ? 
                            <option value={category.categoryId}>Не найдено</option>: ''
                        }
                    </Form.Select>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Отмена</Button>
            <Button variant="danger" onClick={onDelete}>Удалить</Button>
            <Button variant="success" type="submit">Сохранить</Button>
        </Modal.Footer>
        </Form>
    </Modal>
}