import { DownloadFile, HttpData, HttpNoData } from '../../../Core'
export async function postExcel(jwt, body) {
	const response = await HttpData(`/api/v1/Notification/`, 'POST', jwt, body)
	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function downloadExcel(jwt, body) {
	const response = await DownloadFile(
		`/api/v1/Salary/excel`,
		'POST',
		jwt,
		body,
		new Date()
			.toJSON()
			.replaceAll(':', '_')
			.replaceAll('-', '_')
			.replaceAll('.', '_') + '.xlsx'
	)
	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getWorkers(jwt) {
	const response = await HttpNoData('/api/v1/Workers', 'GET', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getExtraWorkCategories(jwt) {
	const response = await HttpNoData('/api/v0/WorkerCategories', 'GET', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function createFine(jwt, data) {
	const response = await HttpData('/api/v1/Salary/fines', 'POST', jwt, data)

	if (!response.statusSuccessful) {
		console.log(response.error)
	}
	return response.data
}

export async function getUserFines(jwt, date, id) {
	const response = await HttpNoData(
		`/api/v1/Salary/workers/${id}/fines/${date}`,
		'GET',
		jwt
	)

	if (!response.statusSuccessful) console.log(response.error)

	return [response.data, response.statusSuccessful]
}

export async function putUserFine(jwt, id, data) {
	const response = await HttpData(
		`/api/v1/Salary/fines/${id}`,
		'PUT',
		jwt,
		data
	)

	return response.statusSuccessful
}

export async function deleteUserFine(jwt, id) {
	const response = await HttpNoData(`/api/v1/Salary/fines/${id}`, 'DELETE', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getQualifications(jwt) {
	const response = await HttpNoData(
		'/api/v1/Workers/qualificationTypes',
		'GET',
		jwt
	)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function updateQualification(jwt, id, value) {
	const response = await HttpNoData(
		`/api/v1/Workers/qualificationTypes/${id}?value=${value}`,
		'PUT',
		jwt
	)

	return response.statusSuccessful
}
