import { Badge, Button, Form, InputGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './style.module.css';
import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';

export default function DiscountProducts({ discounts, products, onUpdate }) {

    const [isModal, setIsModal] = useState(false);

    const [form, setForm] = useState({
        name: '',
        math: 1,
        value: '',
        products: []
    });

    const ItemView = ({ item }) => {

        return <InputGroup className="mb-3">
        <InputGroup.Checkbox checked={item.isUse} onChange={(e) => 
        {
            item.isUse = e.target.checked;
            onUpdate([...discounts]);
        }}/>
        <OverlayTrigger overlay={<Tooltip id={`addition-in-order-custom-tooltip-${item.id}`}>{ item.products.map(map => map.name).join(', ') }</Tooltip>}>
        <InputGroup.Text>{ item.name }</InputGroup.Text>
            </OverlayTrigger>
        <Form.Control
            id={`addition-in-order-custom-${item.id}`}
            value={item.value}
            onChange={() => {}}
            readOnly
        />
        <InputGroup.Text>{ item.math == 1 ? '₽' : '%'}</InputGroup.Text>
        <Button variant='danger' onClick={() => onUpdate([...discounts.filter(f => f.id != item.id)])}/>
    </InputGroup>
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const lastId = discounts[discounts.length - 1]?.id;

        onUpdate([...discounts, { id: lastId ? lastId + 1 : 1, ...form, isUse: true }]);
        setForm({
            name: '',
            math: 1,
            value: '',
            products: []
        });
        setIsModal(false);
    }

    return <div className='d-grid gap-2'>
        {
            discounts.map(item => <ItemView key={item.id} item={item}/>)
        }
        <Button onClick={() => setIsModal(true)}>Добавить скидку</Button>

        {
            isModal ? 
            <Modal show={isModal} onHide={() => setIsModal(false)}>
                <Form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Создание скидки</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mb-3'>
                        <Form.Label>Название</Form.Label>
                        <Form.Control
                            type='text'
                            required
                            value={form.name}
                            onChange={(e) => setForm({ ...form, name: e.target.value})}
                            />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>Тип скидки</Form.Label>
                        <Form.Select
                            required
                            value={form.math}
                            onChange={(e) => setForm({ ...form, math: e.target.value})}
                            >
                                <option value={1}>В Рублях</option>
                                <option value={2}>В Процентах</option>
                            </Form.Select>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>Значение</Form.Label>
                        <Form.Control
                            type='number'
                            required
                            value={form.value}
                            onChange={(e) => setForm({ ...form, value: e.target.value})}
                            />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>Продукция</Form.Label>
                        <Form.Select
                            required
                            value={-1}
                            onChange={(e) => setForm({ ...form, products: [...form.products, products.find(f => f.id == e.target.value)]})}
                            >
                                <option value={-1}>Выбрать</option>
                                {
                                    products.filter(f => form.products.findIndex(find => find.id == f.id) == -1)
                                        .map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                }
                            </Form.Select>
                            {
                                form.products.map(item => <Badge 
                                    key={item.id} 
                                    bg='danger' 
                                    style={{ cursor: 'pointer'}} 
                                    onClick={() => setForm({ ...form, products: [...form.products.filter(f => f.id != item.id)]})} 
                                    pill>
                                        {item.name} 
                                        <MdClose/>
                                    </Badge>)
                            }
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setIsModal(false)}>Закрыть</Button>
                    <Button variant='success' type='submit' disabled={form.products.length == 0}>Создать</Button>
                </Modal.Footer>
                </Form>
            </Modal>
            : ''
        }
    </div>
}