import { useEffect, useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'

const WorkersModal = ({
	isMobile,
	show,
	onHide,
	workers,
	categories,
	selectedCategory,
	onSelect,
}) => {

	const [selectedWorkers, setSelectedWorkers] = useState([])

	const filteredWorkers = selectedCategory
		? workers.filter(worker => worker.categoryId === selectedCategory)
		: workers

	const selectedCategoryName = categories.find(
		category => category.id === selectedCategory
	)?.name

	const handleWorkerSelect = worker => {
		setSelectedWorkers(prevState => {
			if (prevState.includes(worker.id)) {
				return prevState.filter(id => id !== worker.id)
			} else {
				return [...prevState, worker.id]
			}
		})
	}

	const handleSelectAll = () => {
		if (selectedWorkers.length === filteredWorkers.length) {
			setSelectedWorkers([])
		} else {
			setSelectedWorkers(filteredWorkers.map(worker => worker.id))
		}
	}

	const handleModalClose = () => {
		onHide()
		onSelect(
			selectedWorkers.map(id => workers.find(worker => worker.id === id))
		)
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					{selectedCategoryName
						? `Работники категории "${selectedCategoryName}"`
						: 'Выбор работников'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Check
						type='checkbox'
						label='Выбрать всех'
						onChange={handleSelectAll}
						checked={workers.length == selectedWorkers.length}
					/>
					<Table striped bordered hover>
						<thead>
							<tr>
								<th></th>
								<th>ФИО</th>
								{!isMobile ? <th>Телефон</th> : ''}
								<th>Категория</th>
							</tr>
						</thead>
						<tbody>
							{filteredWorkers.map(worker => {
								const categoryName = categories.find(
									category => category.id === worker.categoryId
								)?.name
								return (
									<tr key={worker.id}>
										<td>
											<Form.Check
												type='checkbox'
												checked={selectedWorkers.includes(worker.id)}
												onChange={() => handleWorkerSelect(worker)}
											/>
										</td>
										<td
											role='button'
											onClick={() => handleWorkerSelect(worker)}
										>{`${worker.secondName} ${worker.firstName}  ${worker.thirdName}`}</td>
										{!isMobile ? (
											<td
												role='button'
												onClick={() => handleWorkerSelect(worker)}
											>
												{worker.phone}
											</td>
										) : (
											''
										)}
										<td
											role='button'
											onClick={() => handleWorkerSelect(worker)}
										>
											{categoryName}
										</td>
									</tr>
								)
							})}
						</tbody>
					</Table>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={() => onHide()}>
					Закрыть
				</Button>
				<Button onClick={handleModalClose}>Выбрать</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default WorkersModal
