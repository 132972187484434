import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { createCategory } from "../rawMaterialWarehouseApi";


export default function Create({ show, onHide, onCreated }) {

     const [category, setCategory] = useState({ name: '' });

     const submitCategory = async(e) => {

        e.preventDefault();

        const responseCategory = await createCategory(category);

        if(responseCategory && responseCategory.id > 0)
            onCreated(responseCategory);
        else alert("Что-то пошло не так обратитесь в поддержку");
     }

        return <Modal show={show} onHide={() => onHide(false)} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
            <Form onSubmit={(e) => submitCategory(e)}>
            <Modal.Header closeButton>
                <Modal.Title>Создание новой категории</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput10">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Категория 1"
                        value={category.name}
                        onChange={(e) => setCategory({ ...category, name: e.target.value })}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide(false)}>Отмена</Button>
                <Button variant="success" type="submit">Создать</Button>
            </Modal.Footer>
                </Form>
        </Modal>;
}