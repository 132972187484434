import { useEffect, useState } from "react";
import { Alert, Button, Form, InputGroup, ListGroup, Modal } from "react-bootstrap";
import { HttpData, HttpNoData } from "../../../../Core";
import { MdEdit } from "react-icons/md";
import RangeSizeEdit from "./RangeSizeEdit";
import { HiPlus } from "react-icons/hi";
import AdditionEdit from "./AdditionInProductEdit";
import RangeThicknessEdit from "./RangeThicknessEdit";
import RangePriceOnCount from './RangePriceOnCount';

export default function ProductModalEdit({ jwt, product, show, categories, onHide, onDeleted, onUpdated }) {

    const [name, setName] = useState(product.name);
    const [description, setDescription] = useState(product.description);
    const [categoryId, setCategoryId] = useState(product.categoryId);
    const [maxSize, setMaxSize] = useState(product.maxSize);
    const [minSize, setMinSize] = useState(product.minSize);
    const [standardThickness, setStandardThickness] = useState(product.standardThickness);
    const [standardWeight, setStandardWeight] = useState(product.standardWeight);
    const [standardSize, setStandardSize] = useState(product.standardSize);

    const [additions, setAdditions] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [thickness, setThickness] = useState([]);
    const [priceOnCounts, setPriceOnCounts] = useState([]);

    const [isEditAdditions, setEditAdditions] = useState(false);
    const [isEditSizes, setEditSizes] = useState(false);
    const [isEditThickness, setIsEditThickness] = useState(false);
    const [isEditPriceOnCount, setIsEditPriceOnCount] = useState(false);

    useEffect(() => {
        async function didMount() {
            const sizesResponse = await HttpNoData(`/api/v1/calculateOrderCost/productRangeSizes/${product.id}`, 'GET', jwt);
            setSizes(sizesResponse.data ?? []);

            const additionsResponse = await HttpNoData(`/api/v1/calculateOrderCost/products/additions/${product.id}`, 'GET', jwt);
            setAdditions(additionsResponse.data ?? []);

            const thicknessResponse = await HttpNoData(`/api/v1/calculateOrderCost/productRangeThickness/${product.id}`, 'GET', jwt);
            setThickness(thicknessResponse.data ?? []);

            const pricesOnCountResponse = await HttpNoData(`/api/v1/calculateOrderCost/products/countPrices/${product.id}`, 'GET', jwt);
            setPriceOnCounts(pricesOnCountResponse.data ?? []);
        }

        didMount();
    }, []);

    const createSizeAsync = async (size, price, productId) => {

        const sizesResponse = await HttpData(`/api/v1/calculateOrderCost/productRangeSizes`, 'POST', jwt, {
            price: +price,
            size: +size,
            productId: +productId
        });

        if (sizesResponse.statusSuccessful)
            setSizes([...sizes, sizesResponse.data]);
        else alert('Не получилось создать')
    }

    const createAdditionAsync = async (name, price, productId) => {

        const sizesResponse = await HttpData(`/api/v1/calculateOrderCost/products/additions`, 'POST', jwt, {
            price: +price,
            name: +name,
            productId: +productId
        });

        if (sizesResponse.statusSuccessful)
            setAdditions([...additions, sizesResponse.data]);
        else alert('Не получилось создать')
    }

    const createThicknessAsync = async (thicknessValue, percent, productId) => {

        const sizesResponse = await HttpData(`/api/v1/calculateOrderCost/productRangeThickness`, 'POST', jwt, {
            thickness: +thicknessValue,
            percent: +percent,
            productId: +productId
        });

        if (sizesResponse.statusSuccessful)
            setThickness([...thickness, sizesResponse.data]);
        else alert('Не получилось создать')
    }

    const createPriceOnCountAsync = async (count, discount, productId) => {

        const sizesResponse = await HttpData(`/api/v1/calculateOrderCost/products/countPrices`, 'POST', jwt, {
            count: +count,
            discount: +discount,
            productId: +productId
        });

        if (sizesResponse.statusSuccessful)
            setPriceOnCounts([...priceOnCounts, sizesResponse.data]);
        else alert('Не получилось создать')
    }


    const onSubmit = async (e) => {
        e.preventDefault();

        const response = await HttpData(`/api/v1/calculateOrderCost/products/${product.id}`, 'PUT', jwt, {
            name: name,
            description: description,
            categoryId: +categoryId,
            maxSize: +maxSize,
            minSize: +minSize,
            standardThickness: +standardThickness,
            standardWeight: +standardWeight,
            standardSize: +standardSize
        });

        if (response.statusSuccessful)
            onUpdated({
                ...product,
                name: name,
                description: description,
                categoryId: +categoryId,
                maxSize: +maxSize,
                minSize: +minSize,
                standardThickness: +standardThickness,
                standardWeight: +standardWeight,
                standardSize: +standardSize
            });
        else alert(`Не получилось обновить: ${response.error}`)
    }

    const onDelete = async () => {

        const response = await HttpNoData(`/api/v1/calculateOrderCost/products/${product.id}`, 'DELETE', jwt);

        if (response.statusSuccessful)
            onDeleted();
        else alert(`Не получилось удалить: ${response.error}`)
    }

    if (isEditSizes)
        return <Modal show={isEditSizes} onHide={() => setEditSizes(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Обновление {product.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Размеры</Form.Label>
                    <ListGroup>
                        {
                            sizes.length > 0 ?
                                sizes.map(size => <RangeSizeEdit jwt={jwt} rangeSize={size} onDeleted={() => setSizes([...sizes.filter(f => f.id != size.id)])} />)
                                : <Alert variant="warning">Пусто</Alert>
                        }
                        <Button variant="success" onClick={() => {
                            const size = +prompt('Введите размер (в мм)', 100)
                            const price = +prompt('Введите стоимость', 1000)

                            createSizeAsync(size, price, product.id);
                        }}>
                            <HiPlus />
                        </Button>
                    </ListGroup>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setEditSizes(false)}>Назад</Button>
            </Modal.Footer>
        </Modal>;

    if (isEditAdditions)
        return <Modal show={isEditAdditions} onHide={() => setEditAdditions(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Обновление {product.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Допики.</Form.Label>
                    <ListGroup>
                        {
                            additions.length > 0 ?
                                additions.map(addition => <AdditionEdit jwt={jwt} addition={addition} onDeleted={() => setAdditions([...additions.filter(f => f.id != addition.id)])} />)
                                : <Alert variant="warning">Пусто</Alert>
                        }
                        <Button variant="success" onClick={() => {
                            const name = prompt('Введите название', 'Допик ')
                            const price = +prompt('Введите стоимость', 1000)

                            createAdditionAsync(name, price, product.id);
                        }}>
                            <HiPlus />
                        </Button>
                    </ListGroup>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setEditAdditions(false)}>Назад</Button>
            </Modal.Footer>
        </Modal>;

    if (isEditThickness)
        return <Modal show={isEditThickness} onHide={() => setIsEditThickness(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Обновление {product.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Толщина</Form.Label>
                    <ListGroup>
                        {
                            thickness.length > 0 ?
                                thickness.map(item => <RangeThicknessEdit jwt={jwt} rangeThickness={item} onDeleted={() => setThickness([...thickness.filter(f => f.id != item.id)])} />)
                                : <Alert variant="warning">Пусто</Alert>
                        }
                        <Button variant="success" onClick={() => {
                            const thickness = prompt('Введите размер (в мм)', 6)
                            const percent = +prompt('Введите ставку (в %)', 10)

                            createThicknessAsync(thickness, percent, product.id);
                        }}>
                            <HiPlus />
                        </Button>
                    </ListGroup>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setIsEditThickness(false)}>Назад</Button>
            </Modal.Footer>
        </Modal>;

if (isEditPriceOnCount)
    return <Modal show={isEditPriceOnCount} onHide={() => setIsEditPriceOnCount(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Обновление {product.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3">
                <Form.Label>Скидки от кол-во</Form.Label>
                <ListGroup>
                    {
                        priceOnCounts.length > 0 ?
                        priceOnCounts.map(item => <RangePriceOnCount jwt={jwt} range={item} onDeleted={() => setPriceOnCounts([...priceOnCounts.filter(f => f.id != item.id)])} />)
                            : <Alert variant="warning">Пусто</Alert>
                    }
                    <Button variant="success" onClick={() => {
                        const count = +prompt('Введите кол-во', 100)
                        const discount = +prompt('Введите скидку на шт.', 10)

                        createPriceOnCountAsync(count, discount, product.id);
                    }}>
                        <HiPlus />
                    </Button>
                </ListGroup>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsEditPriceOnCount(false)}>Назад</Button>
        </Modal.Footer>
    </Modal>;

    return <Modal show={show} onHide={onHide}>
        <Form onSubmit={onSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Обновление {product.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Описание</Form.Label>
                    <Form.Control
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Категория</Form.Label>
                    <Form.Select
                        required
                        value={categoryId}
                        onChange={(e) => setCategoryId(e.target.value)}
                    >
                        {
                            categories.map(o => <option key={o.id} value={o.id}>{o.name}</option>)
                        }{
                            categories.findIndex(f => f.id == product.categoryId) == -1 ?
                                <option value={product.categoryId}>Не найдено</option> : ''
                        }
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Параметры</Form.Label>
                    <InputGroup>
																			<InputGroup.Text>Размеры</InputGroup.Text>
                        <Form.Control value={sizes.length} readOnly />
                        <Button style={{ marginRight: 6 }} onClick={() => setEditSizes(true)}>
                            <MdEdit />
                        </Button>
                    </InputGroup>
																<InputGroup>
                        <InputGroup.Text>Допики.</InputGroup.Text>
                        <Form.Control value={additions.length} readOnly />
                        <Button onClick={() => setEditAdditions(true)}>
                            <MdEdit />
                        </Button>
                    </InputGroup>

                    <InputGroup>
                        <InputGroup.Text>Толщина</InputGroup.Text>
                        <Form.Control value={thickness.length} readOnly />
                        <Button onClick={() => setIsEditThickness(true)}>
                            <MdEdit />
                        </Button>
                    </InputGroup>

                    <InputGroup>
                        <InputGroup.Text>Скидки (шт.)</InputGroup.Text>
                        <Form.Control value={priceOnCounts.length} readOnly />
                        <Button onClick={() => setIsEditPriceOnCount(true)}>
                            <MdEdit />
                        </Button>
                    </InputGroup>

                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Максимальный размер</Form.Label>
                    <Form.Control
                        required
                        type='number'
                        value={maxSize}
                        onChange={(e) => setMaxSize(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Минимальный размер</Form.Label>
                    <Form.Control
                        required
                        type='number'
                        value={minSize}
                        onChange={(e) => setMinSize(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Стандартная толщина</Form.Label>
                    <Form.Control
                        required
                        type='number'
                        value={standardThickness}
                        onChange={(e) => setStandardThickness(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Стандартный вес</Form.Label>
                    <Form.Control
                        required
                        type='number'
                        value={standardWeight}
                        onChange={(e) => setStandardWeight(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Стандартный размер</Form.Label>
                    <Form.Control
                        required
                        type='number'
                        value={standardSize}
                        onChange={(e) => setStandardSize(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Отмена</Button>
                <Button variant="danger" onClick={onDelete}>Удалить</Button>
                <Button variant="success" type="submit">Сохранить</Button>
            </Modal.Footer>
        </Form>
    </Modal>
}