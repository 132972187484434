import { HttpData, HttpNoData } from '../../../Core'

export async function getWorkers(jwt) {
	const response = await HttpNoData('/api/v1/Workers', 'GET', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getWorkerCategories(jwt) {
	const response = await HttpNoData('/api/v0/WorkerCategories', 'GET', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getWorkerTypes(jwt) {
	const response = await HttpNoData('/api/v1/Workers/types', 'GET', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getWorkerMathSalary(jwt) {
	const response = await HttpNoData('/api/v1/Workers/mathSalaries', 'GET', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getWorkerEmploymentTypes(jwt) {
	const response = await HttpNoData(
		'/api/v1/Workers/employmentTypes',
		'GET',
		jwt
	)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getWorkerMathTime(jwt) {
	const response = await HttpNoData('/api/v1/Workers/mathTimes', 'GET', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function getWorkerQualificationTypes(jwt) {
	const response = await HttpNoData(
		'/api/v1/Workers/qualificationTypes',
		'GET',
		jwt
	)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function updateWorker(jwt, id, body) {
	const response = await HttpData(`/api/v1/Workers/${id}`, 'PUT', jwt, body)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}

export async function deleteWorker(jwt, id) {
	const response = await HttpNoData(`/api/v1/Workers/${id}`, 'DELETE', jwt)

	if (!response.statusSuccessful) console.log(response.error)

	return response.data
}
