import { useEffect, useState } from 'react'
import {
	Alert,
	Button,
	Container,
	Form,
	ListGroup,
	Modal,
	Stack,
} from 'react-bootstrap'
import {
	createFine,
	deleteUserFine,
	getUserFines,
	putUserFine,
} from './SalaryAPI'
import { MdCheck, MdClose, MdModeEdit, MdOutlineDelete } from 'react-icons/md'

const Worker = ({ workerData, isMobile, jwt }) => {
	const [isFineCreate, setIsFineCreate] = useState(false)
	const [isShowFines, setIsShowFines] = useState(false)
	const [fines, setFines] = useState([])
	const [validated, setValidated] = useState(false)
	const [selectedMonth, setSelectedMonth] = useState(new Date().toJSON().split('T')[0])
	const [isLoaded, setIsLoaded] = useState(false)
	const [createFineMessage, setCreateFineMessage] = useState('')

	const handleSubmit = async(event) => {
		const form = event.currentTarget;

		event.preventDefault();

		if(form.checkValidity()){

			await createFine(jwt, {
				workerId: workerData.id,
				message: createFineMessage,
				date: selectedMonth + 'T00:00:00'
			});
	
			setCreateFineMessage('');
			setIsFineCreate(false);
			setValidated(false);

			await getFinesList(selectedMonth);

		} else setValidated(true);
	}

	const handleFineDelete = id => {
		deleteUserFine(jwt, id)
		setFines(fines.filter(fine => id !== fine.id))
	}

	const getFinesList = async (date) => {
		const [data, status] = await getUserFines(jwt, date, workerData.id)
		setFines(data)
		setIsLoaded(status)
	}

	useEffect(() => {
		getFinesList(selectedMonth);
	}, [selectedMonth]);



	const FineItem = ({ fine }) => {

		const [newMessage, setNewMessage] = useState(fine.message);
		const [isEdit, setIsEdit] = useState(false);

		const updateFine = async () => {
			const status = await putUserFine(jwt, fine.id, { message: newMessage });

			if (status) {
				fine.message = newMessage;
				setIsEdit(false);
			} else alert('Сервис сейчас не доступен');
		}

		if (isEdit)
			return <ListGroup.Item key={fine.id} style={{ padding: '18px 16px', transition: '0.5s', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Form.Control
					value={newMessage}
					onChange={e => setNewMessage(e.target.value)}
				/>
				<Stack direction='horizontal' gap={3}>
					<Button disabled={!newMessage || newMessage.trim() == '' || fine.message == newMessage} variant={'success'} onClick={() => updateFine()}>
						<MdCheck />
					</Button>
					<Button variant='danger' onClick={() => setIsEdit(false)}>
						<MdClose />
					</Button>
				</Stack>
			</ListGroup.Item>

		return <ListGroup.Item key={fine.id} style={{ padding: '8px 16px', transition: '0.5s', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
			<span>{fine.message}</span>
			<Stack direction='horizontal' gap={3}>
				<Button variant='warning' onClick={() => setIsEdit(true)}>
					<MdModeEdit />
				</Button>
				<Button variant='danger' onClick={() => handleFineDelete(fine.id)}>
					<MdOutlineDelete />
				</Button>
			</Stack>
		</ListGroup.Item>
	}

	return (
		<Container>
			{isFineCreate ? (
				<Modal show={isFineCreate} onHide={() => setIsFineCreate(false)}>
					<Form noValidate  validated={validated} onSubmit={handleSubmit}>
						<Modal.Header closeButton>
							<h2>Выписать штраф</h2>
						</Modal.Header>
						<Modal.Body>
							<Form.Group>
							<Form.Label htmlFor='fineMessage'>Сообщение штрафа</Form.Label>
							<Form.Control
								required
								type='text'
								id='fineMessage'
								value={createFineMessage}
								onChange={e =>
									setCreateFineMessage(e.target.value )
								}
								className='mb-3'
								/>
								<Form.Control.Feedback type='invalid'>Обязательно!</Form.Control.Feedback>
								</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button variant='secondary' onClick={() => setIsFineCreate(false)}>
								Закрыть
							</Button>
							<Button variant='success' type='submit'>
								Создать
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			) : (
				''
			)}
			{isShowFines && !isFineCreate ? (
				<Modal show={isShowFines} onHide={() => setIsShowFines(false)}>
					<Modal.Header closeButton className=''>
						<h2>Выберите месяц</h2>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Control
								required
								type='date'
								id='fineDate'
								className='mb-3'
								value={selectedMonth}
								onChange={e => setSelectedMonth(e.target.value)}
							/>
						</Form>
						{fines && fines.length > 0 ? (
							fines.map(fine => <FineItem key={fine.id} fine={fine} />)
						) : fines && isLoaded && fines.length === 0 ? (
							<ListGroup.Item>Штрафов нет</ListGroup.Item>
						) : (
							''
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button variant='danger' style={{ whiteSpace: 'nowrap' }} onClick={() => setIsFineCreate(true)}>
							Выписать штраф
						</Button>
					</Modal.Footer>
				</Modal>
			) : (
				''
			)}
			<Stack
				style={{ width: '100%', padding: '4px 8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
				gap={1}
				direction={isMobile ? 'vertical' : 'horizontal'}
			>
				<span style={{ whiteSpace: 'nowrap' }}>
					{`${workerData.secondName} ${workerData.firstName} ${workerData.thirdName}`}
				</span>
				<Stack gap={2} direction={isMobile ? 'vertical' : 'horizontal'}>
					<Button
						variant='warning'
						onClick={() => setIsShowFines(true)}
						style={{ whiteSpace: 'nowrap' }}
					>
						Управление
					</Button>
				</Stack>
			</Stack>
		</Container>
	)
}

export default Worker
