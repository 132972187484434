import React, { useState } from 'react'
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { postExtraWork } from '../../apiMethod/extraWorks'
export const AddExtraWork = ({ props }) => {
	const [jwt, updateExtraWorksList, extraWorkCategoriesList] = props
	const [show, setShow] = useState(false)
	const [extraWorkData, setExtraWorkData] = useState({
		name: '',
		coefficient: 1,
		extraWorkCategoryId: 3,
		complexity: 0,
		isUserCheck: true,
	})

	const extraWorkLevel = [
		{ id: 0, name: 'Не выбрана' },
		{ id: 1, name: 'Простая' },
		{ id: 2, name: 'Средняя' },
		{ id: 3, name: 'Сложная' },
	]

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const updateList = async(extraWorkData) => {
		const response = await postExtraWork(jwt, {
			name: extraWorkData.name,
			coefficient: +extraWorkData.coefficient,
			complexity: +extraWorkData.complexity,
			extraWorkCategoryId: +extraWorkData.extraWorkCategoryId	
		})
		updateExtraWorksList(response);
		handleClose();
	}
	const [validated, setValidated] = useState(false)

	const handleSubmit = event => {
		const form = event.currentTarget
		event.preventDefault()

		if (form.checkValidity() === false) {
			event.stopPropagation()
		} else {
			updateList(extraWorkData)
		}

		setValidated(true)
	}

	return (
		<>
			<Button
				variant='primary'
				style={{ marginLeft: 4, marginBottom: 12 }}
				onClick={handleShow}
			>
				Создать работу
			</Button>
			<Modal show={show} onHide={handleClose}>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>Создать работу</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
							<Form.Label>Название</Form.Label>
							<Form.Control
								value={extraWorkData.name}
								required
								onChange={e =>
									setExtraWorkData({
										...extraWorkData,
										name: e.target.value,
									})
								}
								autoFocus
							/>
							<Form.Control.Feedback type={'invalid'}>
								Обязательно
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group className='mb-3'>
							<Form.Label>Коэффициент</Form.Label>
							<Form.Control
								type='number'
								value={extraWorkData.coefficient}
								onChange={e =>
									console.log(
										setExtraWorkData({
											...extraWorkData,
											coefficient: e.target.value,
										})
									)
								}
								autoFocus
							/>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Категория</Form.Label>
							<FloatingLabel label='Выберите категорию'>
								<Form.Select
									aria-label='Выберите категорию'
									value={extraWorkData.extraWorkCategoryId}
									onChange={e =>
										setExtraWorkData({
											...extraWorkData,
											extraWorkCategoryId: e.target.value,
										})
									}
								>
									{extraWorkCategoriesList &&
									extraWorkCategoriesList.length > 0 ? (
										extraWorkCategoriesList.map((obj, id) => (
											<option value={obj.id} key={id}>
												{obj.name}
											</option>
										))
									) : (
										<option>Категории работ куда-то пропали...</option>
									)}{' '}
								</Form.Select>
							</FloatingLabel>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Сложность</Form.Label>
							<FloatingLabel label='Выберите сложность'>
								<Form.Select
									aria-label='Выберите сложность'
									value={extraWorkData.complexity}
									onChange={e =>
										setExtraWorkData({
											...extraWorkData,
											complexity: e.target.value,
										})
									}
								>
									{extraWorkLevel.map((obj, id) => (
										<option value={obj.id} key={id}>
											{obj.name}
										</option>
									))}
								</Form.Select>
							</FloatingLabel>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={handleClose}>
							Закрыть
						</Button>
						<Button variant='primary' type='submit'>
							Сохранить
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}
