import React from "react";
import { getRandomInt } from "../../Core";
import styles from './HomeStyle.module.css';

const moments = [{
        moment: '«Никто из нас так не умен, как все мы вместе»',
        author: 'Кен Бланшар'
    },
    {
        moment: '«В одиночку мы можем так мало; вместе мы можем свернуть горы»',
        author: 'Хелен Келлер'
    },
    {
        moment: '«Талант выигрывает в играх, командная работа и интеллект выигрывают в чемпионатах»',
        author: 'Майкл Джордан'
    },
    {
        moment: '«В командной работе тишина – это не золото, тишина – это смерть»',
        author: 'Марк Санборн'
    },
    {
        moment: '«Если вы можете вместе развлекаться, вы можете и работать вместе»',
        author: 'Роберт Орбен'
    }
];

function Home({ jwt }) {

    const current = moments[getRandomInt(0, moments.length)];
    return (
        <div className={styles.currentbody}>
            <h3 className={styles.Title}>{current.moment}</h3>
            <h4 className={styles.Author}>{current.author}</h4>
        </div>
    );
}


export default Home;