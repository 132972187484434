import { Button, Form, ListGroup, Stack } from "react-bootstrap";
import { MdCheck, MdClose, MdDelete, MdEdit } from "react-icons/md";
import { useState } from "react";
import { HttpData, HttpNoData } from "../../../../Core";

export default function AdditionEdit({ addition, onDeleted, jwt }) {

    const [isEdit, setIsEdit] = useState(false);

    const [name, setName] = useState(addition.name);
    const [description, setDescription] = useState(addition.description);
    const [price, setPrice] = useState(addition.price);

    const updateAsync = async () => {

        const sizesResponse = await HttpData(`/api/v1/calculateOrderCost/products/additions/${addition.id}`, 'PUT', jwt, {
            price: +price,
            name: name,
            description: description
        });

        if (sizesResponse.statusSuccessful) {
            addition.price = +price;
            addition.name = name;
            addition.description = description;
            setIsEdit(false);
        }
        else alert('Не получилось обновить')
    }

    const deleteAsync = async () => {

        const sizesResponse = await HttpNoData(`/api/v1/calculateOrderCost/products/additions/${addition.id}`, 'DELETE', jwt);

        if (sizesResponse.statusSuccessful)
            onDeleted();
        else alert('Не получилось удалить')
    }

    if (isEdit)
        return <ListGroup.Item>
            <Form.Group className="mb-3">
                <Form.Label>Название</Form.Label>
                <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Цена</Form.Label>
                <Form.Control
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Описание</Form.Label>
                <Form.Control
                    as={'textarea'}
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Form.Group>
            <Stack direction="horizontal" gap={1}>
                <Button variant="success" onClick={updateAsync}>
                    <MdCheck />
                </Button>
                <Button variant="danger" onClick={() => setIsEdit(false)}>
                    <MdClose />
                </Button>
            </Stack>
        </ListGroup.Item>

    return <ListGroup.Item>
        <Form.Group className="mb-3">
            <Form.Label>Название</Form.Label>
            <Form.Control
                plaintext
                readOnly
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Цена</Form.Label>
            <Form.Control
                plaintext
                readOnly
                value={price}
                onChange={(e) => setPrice(e.target.value)}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Описание</Form.Label>
            <Form.Control
                plaintext
                readOnly
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
        </Form.Group>
        <Stack direction="horizontal" gap={1}>
            <Button variant="warning" onClick={() => setIsEdit(true)}>
                <MdEdit />
            </Button>
            <Button variant="danger" onClick={deleteAsync}>
                <MdDelete />
            </Button>
        </Stack>
    </ListGroup.Item>
};