import Product from "./Product";
import { useEffect, useState } from "react";
import styles from './style.module.css';
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

export default function ProductCategory({ jwt, category, keyUnq, fileOptions, products, onUpdate, onSum, onWeight, onUpdateProduct }) {

    const [currentProduct, setCurrentProduct] = useState();
    const [isUse, setIsUse] = useState(true);

    const [sum, setSum] = useState(0);
    const [weight, setWeight] = useState(0);

    useEffect(() => {

        if(!fileOptions)
            return;

        const find = fileOptions.products.find(f => f.key == keyUnq);

        if(!find)
            return;

        setCurrentProduct(find);

    }, [fileOptions]);

    useEffect(() => {

        if (!isUse) {
            onSum(category, 0,currentProduct);
            onWeight(category, 0);
        } else {
            onSum(category, sum, currentProduct);
            onWeight(category, weight);
        }
    }, [isUse]);

    useEffect(() => {
        onSum(category, sum, currentProduct);
    }, [sum]);

    useEffect(() => {
        onWeight(category, weight);
    }, [weight]);

    useEffect(() => {

        onUpdateProduct(currentProduct);

        if (currentProduct)
            return;

        onSum(category, 0, currentProduct);
        onWeight(category, 0);
    }, [currentProduct]);

    const currentProducts = products.filter(f => f.categoryId == category.id);

    return <div className={styles.productCategory}>
        <div className={styles.container_category}>
        <OverlayTrigger overlay={<Tooltip id={`category-tooltip-${category.id}`}>{category.description}</Tooltip>}>
        <span style={{ marginRight: '8px', fontWeight: 800 }}>{category.name} - </span>
            </OverlayTrigger>
            
            {
                !currentProduct ?
                    <Form.Select
                        defaultValue={-1}
                        style={{ width: '166px' }}
                        onChange={e => setCurrentProduct(currentProducts.find(f => f.id == e.target.value))}
                    >
                        <option value={-1}>Не выбрано</option>
                        {
                            currentProducts.map(pr => <option key={pr.id} value={pr.id}>{pr.name}</option>)
                        }
                    </Form.Select>
                    : <>
                        <span style={{ marginBottom: 0, fontWeight: 800 }}>{currentProduct.name}</span>
                        <Form.Check
                            id={`checkbox-product-${currentProduct.id}`}
                            checked={isUse}
                            onChange={e => setIsUse(e.target.checked)}
                            style={{ height: 41, marginRight: 10}}
                        />
                        <Button variant="danger" id={`checkbox-product-${currentProduct.id}-remove`}
                            className="me-3"
                            onClick={() => setCurrentProduct()}
                        >
                            <MdDelete/>
                        </Button>
                    </>
            }
        </div>
        {
            currentProduct ?
                <Product
                    jwt={jwt}
                    isUse={isUse}
                    onUpdate={onUpdate}
                    product={currentProduct}
                    onSum={(p, s) => setSum(s)}
                    onWeight={(p, s) => setWeight(s)} />
                : ''
        }
    </div>
}