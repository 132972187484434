import { useState } from "react";
import { moveSumOnWarehouses } from "../rawMaterialWarehouseApi";
import { Alert, Button, Form, Modal } from "react-bootstrap";

export default function MoveSum({ materialId, currentWarehouseId, show, onHide, warehouses, onMoved }){

    const [moveBody, setMoveBody] = useState({ 
        materialId: materialId,
        message: '',
        value: 0,
        fromWarehouseId: currentWarehouseId,
        toWarehouseId: null
    });

    const submitMove = async(e) => {

       e.preventDefault();

       const responseStatus = await moveSumOnWarehouses(moveBody);

       if(responseStatus)
            onMoved(moveBody.value);
       else alert("Что-то пошло не так обратитесь в поддержку");
    }

       return <Modal show={show} onHide={() => onHide(false)} size="lg"
       aria-labelledby="contained-modal-title-vcenter"
       centered>
           <Form onSubmit={(e) => submitMove(e)}>
           <Modal.Header closeButton>
               <Modal.Title>Перевести на другой склад</Modal.Title>
           </Modal.Header>
           <Modal.Body>
               <Form.Group className="mb-3" controlId="exampleForm.ControlInput10">
                   <Form.Label>Информация</Form.Label>
                   <Form.Control
                       type="text"
                       placeholder="Перевод потому что...."
                       value={moveBody.message}
                       onChange={(e) => setMoveBody({ ...moveBody, message: e.target.value })}
                   />
               </Form.Group>
               <Form.Group className="mb-3" controlId="exampleForm.ControlInput12">
                   <Form.Label>Кол-во</Form.Label>
                   <Form.Control
                       type="number"
                       placeholder="1000"
                       value={moveBody.value}
                       onChange={(e) => setMoveBody({ ...moveBody, value: e.target.value })}
                   />
               </Form.Group>
               <Form.Group className="mb-3" controlId="exampleForm.ControlInput12">
                   <Form.Label>Куда</Form.Label>
                   <Form.Select required
                       value={moveBody.toWarehouseId ?? -1}
                       onChange={(e) => setMoveBody({ ...moveBody, toWarehouseId: e.target.value })}
                   >
                    {
                        warehouses && warehouses.length > 0 ?
                        warehouses.map(w => <option key={w.id} value={w.id}>{w.name}</option>):
                        ''
                    }
                   </Form.Select>
               </Form.Group>
               <Alert variant="warning">Сумма спишется с текущего склада и будет начислена на выбранный, игнорируя коэффициент.</Alert>
           </Modal.Body>
           <Modal.Footer>
               <Button variant="secondary" onClick={() => onHide(false)}>Отмена</Button>
               <Button variant="success" type="submit">Создать</Button>
           </Modal.Footer>
               </Form>
       </Modal>;
}