import { Button, Form, Modal } from "react-bootstrap";
import { createManagerProduct } from "./productWarehouseApi";
import { useState } from "react";

export default function CreateManagerProductView({ jwt, name, onCreated, show, onHide }) {

    const [product, setProduct] = useState({ name: name });

    const submitProduct = async(e) => {
        e.preventDefault();

        const responseProduct = await createManagerProduct(jwt, product);

        if(responseProduct && responseProduct.id > 0)
            onCreated(responseProduct);
        else alert('Что-то пошло не по плану');
    }

    return <Modal show={show} onHide={onHide} size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
        <Form onSubmit={(e) => submitProduct(e)}>
        <Modal.Header closeButton>
            <Modal.Title>Создание новой отправки</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput10">
                <Form.Label>Название</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Отправки 1999"
                    value={product.name}
                    onChange={(e) => setProduct({ ...product, name: e.target.value })}
                />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Отмена</Button>
            <Button variant="success" type="submit">Создать</Button>
        </Modal.Footer>
            </Form>
    </Modal>;
}