import React, { useState } from 'react'
import { Button, Form, ListGroup, Modal, Table } from 'react-bootstrap'

const DesktopTable = ({ worker,
	jwt,
	updateWorker,
	deleteWorker,
	workerCategories,
	workerTypes,
	mathSalaryTypes,
	employmentTypes,
	mathTimeTypes,
	qualificationTypes,
	handleDelete, }) => {

	const [validated, setValidated] = useState(false)
	const [isDeleteModalShow, setIsDeleteModalShow] = useState(false)
	const [workerData, setWorkerData] = useState(worker);
	const [isEdit, setIsEdit] = useState(false);


	const deleteWorkerFunction = () => {
		handleDelete(worker.id)
		setIsDeleteModalShow(!isDeleteModalShow)
		deleteWorker(jwt, workerData.id)
	}

	const handleSubmit = event => {
		const form = event.currentTarget
		event.preventDefault()
		if (form.checkValidity() === false) {
			event.stopPropagation()
		}

		setValidated(true)
		updateWorker(jwt, workerData.id, workerData)
	}

	if (!isEdit)
		return <ListGroup.Item action onClick={() => setIsEdit(true)}>{worker.firstName} {worker.secondName} {worker.thirdName} ({worker.name} - устарело)</ListGroup.Item>

	return (
		<div
			style={{ border: '1px solid black', marginBottom: '10px', padding: '0' }}
		>
			{isDeleteModalShow ? (
				<Modal
					show={isDeleteModalShow}
					onHide={() => setIsDeleteModalShow(!isDeleteModalShow)}
				>
					<Modal.Header>Удалить сотрудника</Modal.Header>
					<Modal.Body>
						Вы действительно хотите <b>Удалить</b> сотрудника?
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant='secondary'
							onClick={() => setIsDeleteModalShow(!isDeleteModalShow)}
						>
							Закрыть
						</Button>
						<Button variant='danger' onClick={() => deleteWorkerFunction()}>
							Удалить
						</Button>
					</Modal.Footer>
				</Modal>
			) : (
				''
			)}
			<Form
				validated={validated}
				onSubmit={handleSubmit}
				style={{
					maxWidth: '1440px',
					padding: '10px',
				}}
			>
				<Table>
					<thead>
						<tr>
							<th style={{ whiteSpace: 'nowrap' }}>Фамилия</th>
							<th style={{ whiteSpace: 'nowrap' }}>Имя</th>
							<th style={{ whiteSpace: 'nowrap' }}>Отчество</th>
							<th style={{ whiteSpace: 'nowrap' }}>ФИО (Устарело)</th>
							<th style={{ whiteSpace: 'nowrap' }}>Телефон</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<Form.Group>
									<Form.Control
										required
										style={{ display: 'inline-block' }}
										type='string'
										value={workerData.secondName}
										onChange={e => {
											setWorkerData({
												...workerData,
												secondName: e.target.value,
											})
										}}
									/>
									<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								<Form.Group>
									<Form.Control
										required
										style={{ display: 'inline-block' }}
										type='string'
										value={workerData.firstName}
										onChange={e => {
											setWorkerData({
												...workerData,
												firstName: e.target.value,
											})
										}}
									/>
									<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								<Form.Group>
									<Form.Control
										type='string'
										value={workerData.thirdName}
										onChange={e => {
											setWorkerData({
												...workerData,
												thirdName: e.target.value,
											})
										}}
									/>
									<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
								</Form.Group>
							</td>
							<td>
								<Form.Group>
									<Form.Control
										readOnly
										disabled
										type='string'
										value={workerData.name}
									/>
								</Form.Group>
							</td>
							<td>
								<Form.Group>
									<Form.Control
										required
										type='tel'
										value={workerData.phone}
										onChange={e => {
											setWorkerData({
												...workerData,
												phone: e.target.value,
											})
										}}
									/>
									<Form.Control.Feedback>Обязательно!</Form.Control.Feedback>
								</Form.Group>
							</td>
						</tr>
					</tbody>
				</Table>
				<Table>
					<thead>
						<tr>
							<th style={{ whiteSpace: 'nowrap' }}>Тип работника</th>
							<th style={{ whiteSpace: 'nowrap' }}>Тип расчета</th>
							<th style={{ whiteSpace: 'nowrap' }}>Тип трудоустройства</th>
							<th style={{ whiteSpace: 'nowrap' }}>График работы</th>
							<th style={{ whiteSpace: 'nowrap' }}>Категория</th>
							<th style={{ whiteSpace: 'nowrap' }}>Квалификация</th>
							<th style={{ whiteSpace: 'nowrap' }}>Часовой пояс</th>
							<th style={{ whiteSpace: 'nowrap' }}>Комментарий</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<Form.Select
									value={workerData.typeId}
									onChange={e => {
										setWorkerData({ ...workerData, typeId: e.target.value })
									}}
								>
									{workerTypes && workerTypes.length > 0
										? workerTypes.map(workerType => (
											<option key={workerType.id} value={workerType.id}>
												{workerType.name}
											</option>
										))
										: ''}

									{
										workerTypes.findIndex(f => f.id == workerData.typeId) != -1 ? ''
											: <option value={-1}>
												Не установлено
											</option>
									}
								</Form.Select>
							</td>
							<td>
								<Form.Select
									value={workerData.mathSalaryId}
									onChange={e => {
										setWorkerData({
											...workerData,
											mathSalaryId: e.target.value,
										})
									}}
								>
									{mathSalaryTypes && mathSalaryTypes.length > 0
										? mathSalaryTypes.map(mathSalaryType => (
											<option
												key={mathSalaryType.id}
												value={mathSalaryType.id}
											>
												{mathSalaryType.name}
											</option>
										))
										: ''}

									{
										mathSalaryTypes.findIndex(f => f.id == workerData.mathSalaryId) != -1 ? ''
											: <option value={-1}>
												Не установлено
											</option>
									}
								</Form.Select>
							</td>
							<td>
								<Form.Select
									style={{ display: 'inline-block' }}
									value={workerData.employmentTypeId}
									onChange={e => {
										setWorkerData({
											...workerData,
											employmentTypeId: e.target.value,
										})
									}}
								>
									{employmentTypes && employmentTypes.length > 0
										? employmentTypes.map(employmentType => (
											<option
												key={employmentType.id}
												value={employmentType.id}
											>
												{employmentType.name}
											</option>
										))
										: ''}
								</Form.Select>
							</td>
							<td>
								<Form.Select
									value={workerData.mathTime}
									onChange={e => {
										setWorkerData({
											...workerData,
											mathTime: e.target.value,
										})
									}}
								>
									{mathTimeTypes && mathTimeTypes.length > 0
										? mathTimeTypes.map(mathTimeType => (
											<option key={mathTimeType.id} value={mathTimeType.id}>
												{mathTimeType.name}
											</option>
										))
										: ''}

									{
										mathTimeTypes.findIndex(f => f.id == workerData.mathTime) != -1 ? ''
											: <option value={-1}>
												Не установлено
											</option>
									}
								</Form.Select>
							</td>
							<td>
								<Form.Select
									value={workerData.categoryId}
									a
									onChange={e => {
										setWorkerData({
											...workerData,
											categoryId: e.target.value,
										})
									}}
								>
									{workerCategories && workerCategories.length > 0
										? workerCategories.map(workerCategory => (
											<option
												key={workerCategory.id}
												value={workerCategory.id}
											>
												{workerCategory.name}
											</option>
										))
										: ''}
								</Form.Select>
							</td>
							<td>
								<Form.Select
									value={workerData.qualificationId ?? -1}
									onChange={e => {
										setWorkerData({
											...workerData,
											qualificationId: e.target.value,
										})
									}}
								>
									{qualificationTypes && qualificationTypes.length > 0 ?
										qualificationTypes.map(qualificationType => <option
											key={qualificationType.id}
											value={qualificationType.id}>
											{qualificationType.name}
										</option>)
										: ''}

									{
										qualificationTypes.findIndex(f => f.id == workerData.qualificationId) != -1 ? ''
											: <option value={-1}>
												Не установлено
											</option>
									}
								</Form.Select>
							</td>
							<td>
								<Form.Control
									type='time'
									value={workerData.zone}
									onChange={e => setWorkerData({ ...workerData, zone: e.target.value })}
								/>
							</td>
							<td>
								<Form.Control
									as="textarea"
									value={workerData.adminNote}
									onChange={e => setWorkerData({ ...workerData, adminNote: e.target.value })}
								/>
							</td>
						</tr>
					</tbody>
				</Table>
				<Button variant='primary' type='submit'>
					Сохранить
				</Button>
				<Button
					style={{ marginLeft: '10px' }}
					variant='danger'
					onClick={() => setIsDeleteModalShow(!isDeleteModalShow)}
				>
					Удалить
				</Button>
				<Button variant='secondary' style={{ marginLeft: '10px' }} onClick={() => setIsEdit(false)}>
					Закрыть
				</Button>
			</Form>
		</div>
	)
}

export default DesktopTable
