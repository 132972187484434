import React, { useEffect, useMemo, useState } from 'react'
import { Col, Container, Row, Stack, Tab, TabContainer, Tabs } from 'react-bootstrap'
import Excel from './Excel'
import Fine from './Fine'
import Qualification from './Qualification'
import { getExtraWorkCategories, getWorkers } from './SalaryAPI'

const Salary = ({ jwt }) => {
	const mobileSize = 1200
	const [workers, setWorkers] = useState([])
	const [workerCategory, setWorkerCategory] = useState([])
	const [width, setWidth] = useState(window.screen.width)
	const [isMobile, setIsMobile] = useState(
		window.screen.width - mobileSize <= 0
	)

	useEffect(() => {
		async function didMount() {
			setWorkers((await getWorkers(jwt)) ?? [])
			setWorkerCategory((await getExtraWorkCategories(jwt)) ?? [])
		}
		didMount()

		window.addEventListener('resize', function (event) {
			setIsMobile(this.document.body.clientWidth - mobileSize <= 0)
			setWidth(this.document.body.clientWidth)
		})
	}, [])

	const content = useMemo(() => {
		if (width <= mobileSize)
			return (
				<TabContainer defaultActiveKey='fines' vertical fill>
					<Tabs defaultActiveKey='fines' fill>
						<Tab eventKey='fines' title='Параметры'>
							<Stack gap={3}>
								<Qualification jwt={jwt} />
								<Fine workers={workers} isMobile={isMobile} jwt={jwt} />
							</Stack>
						</Tab>
						<Tab eventKey='excel' title='Excel'>
							<Excel
								isMobile={isMobile}
								workers={workers}
								workerCategories={workerCategory}
								jwt={jwt}
							/>
						</Tab>
					</Tabs>
				</TabContainer>
			)

		return (
			<Container style={{ maxWidth: '1440px', margin: '40px auto' }}>
				<Row>
					<Col xl={6}>
						<h1 style={{ width: '100%', textAlign: 'center' }}>Параметры</h1>
						<Stack gap={3}>
							<Qualification jwt={jwt} />
							<Fine workers={workers} isMobile={isMobile} jwt={jwt} />
						</Stack>
					</Col>
					<Col xl={6}>
						<Excel
							isMobile={isMobile}
							workers={workers}
							workerCategories={workerCategory}
							jwt={jwt}
						/>
					</Col>
				</Row>
			</Container>
		)
	}, [isMobile, workers, workerCategory])

	return content
}

export default Salary
