import React, { useEffect, useState } from "react";
import styles from '../Styles/FormulasMath.module.css';
import { Alert, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getExtraWorks } from "../../../apiMethod/extraWorks";
import { getProducts } from "../../../apiMethod/products";
import EditFormula from "./EditFormulaModal";
import { getFormulaAmounts } from "../../../apiMethod/analyze";
import { FaPlus } from "react-icons/fa";
import CreateFormula from "./CreateFormulaModal";

function FormulasMath({ jwt }) {

    const [productsRationCurrent, setProductsRationCurrent] = useState([]);
    const [productsRationPrev, setProductsRationPrev] = useState([]);

    const [currentMonth] = useState(new Date());
    const [prevMonth] = useState(new Date(new Date() - new Date().getDate() * 24 * 60 * 60 * 1000));

    const [extraWorks, setExtraWorks] = useState([]);
    const [productions, setProductions] = useState([]);

    const [isCreate, setIsCreate] = useState(false);

    useEffect(() => {

        async function didMount() {

            const current = await getFormulaAmounts(currentMonth.toLocaleDateString().split('.').reverse().join('-') + "T00:00:00", jwt) ?? [];
            const prev = await getFormulaAmounts(prevMonth.toLocaleDateString().split('.').reverse().join('-') + "T00:00:00", jwt);

            const responseExtraWorks = await getExtraWorks(jwt);
            const responseProductions = await getProducts(jwt);

            setExtraWorks(responseExtraWorks ?? []);
            setProductions(responseProductions ?? []);

            setProductsRationCurrent(current);
            setProductsRationPrev(prev);
        }

        didMount();
    }, []);

    const FormulaItem = ({ item }) => {

    const value = +(item.value * 100).toFixed(2);
    const [isEdit, setIsEdit] = useState(false);

        return  <div className={styles.productRation}>
        <div className={styles.productRationName}>
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => <Tooltip {...props}>
                    {item.formula}
                </Tooltip>}>
                <div>{item.name}</div>
            </OverlayTrigger>
        </div>
        <svg
            width="100" height="100" viewBox="0 0 100 100"
            className={styles.circular_progress} style={{ '--progress': value }}
            onClick={() => setIsEdit(true)}
        >
            <circle className={styles.bg + ' ' + styles.circle} />
            <circle className={styles.fg + ' ' + styles.circle} />
        </svg>
        <div style={{ position: 'relative', top: '-60px' }}>{value}%</div>
        {
            isEdit ? <EditFormula item={item} show={isEdit} onHide={() => setIsEdit(false)} extraWorks={extraWorks} productions={productions} onUpdated={() => window.location.reload()} jwt={jwt}/>: ''
        }
        </div>
    }

    return <div className={styles.main}>
        <div className={styles.content}>
            <div className={styles.headContent}>
                <label className={styles.titleChart}>Рентабельность</label>
                <Button variant="success" onClick={() => setIsCreate(true)}><FaPlus/></Button>
            </div>

            <div className={styles.monthBlock}>
                <div className={styles.monthText}>{currentMonth.toLocaleDateString('default', { month: "long" }).replace(/./, c => c.toUpperCase())}</div>
                <div className={styles.stateMonth}>текущий месяц</div>
                <div className={styles.elements}>
                    {
                        productsRationCurrent && productsRationCurrent.length > 0 ?
                            productsRationCurrent.map((item, index) => <FormulaItem key={index} item={item}/>)
                            : <Alert variant="danger" dismissible>
                                <Alert.Heading>Ох, щелк! У вас ошибка!</Alert.Heading>
                                <p>
                                    Что-то пошло не по плану, попробуйте перезагрузить страницу! Если не помогло тогда сообщите разработчику и дождитесь обновления.
                                </p>
                            </Alert>
                    }
                </div>
            </div>

            <div className={styles.monthBlock}>
                <div className={styles.monthText}>{prevMonth.toLocaleDateString('default', { month: "long" }).replace(/./, c => c.toUpperCase())}</div>
                <div className={styles.stateMonth}>прошлый месяц</div>
                <div className={styles.elements}>
                    {
                        productsRationPrev && productsRationPrev.length > 0 ?
                            productsRationPrev.map((item, index) => <FormulaItem key={index} item={item}/>)
                            : <Alert variant="danger" dismissible>
                                <Alert.Heading>Ох, щелк! У вас ошибка!</Alert.Heading>
                                <p>
                                    Что-то пошло не по плану, попробуйте перезагрузить страницу! Если не помогло тогда сообщите разработчику и дождитесь обновления.
                                </p>
                            </Alert>
                    }
                </div>
            </div>
        </div>
        {
            isCreate ?
            <CreateFormula
                extraWorks={extraWorks}
                item={{name: '', formula: '', formulaMath: ''}}
                onHide={() => setIsCreate(false)}
                productions={productions}
                show={isCreate}
                onCreated={() => window.location.reload()}
                jwt={jwt}
            />:''
        }
    </div>;
}

export default FormulasMath;