import React, { useEffect, useState } from "react";
import { Alert, Button, Form, InputGroup, ListGroup, Stack, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { FaRegLightbulb, FaPlus } from "react-icons/fa6";
import { MdDelete, MdClose } from "react-icons/md";
import { IoReloadOutline, IoSend, IoWarningOutline } from "react-icons/io5";
import CreatePlace from "./CreatePlace";
import EditPlace from "./EditPlace";
import CreateProductCategory from "./CreateCategory";
import EditCategory from "./EditCategory";
import popularSends from './websql_query_result_2024_10_21_49.json';
import { createProductSend, deleteProductSend, getPlaces, getProductCategories, getProducts, getProductsSends, updateProductSend } from "../api";
import { FaCircleInfo } from "react-icons/fa6";
import EditProduct from "./EditProduct";
import CreateProduct from "./CreateProduct";
import DragDropCategories from "./DragDropCategories";

function SellProducts({ jwt }) {

    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [places, setPlaces] = useState([]);
    const [currentPlaceId, setCurrentPlaceId] = useState(2);
    const [currentCategoryId, setCurrentCategoryId] = useState(1);

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sends, setSends] = useState([]);

    const [isCreatePlace, setIsCreatePlace] = useState(false);
    const [isCreateCategory, setIsCreateCategory] = useState(false);
    const [isDragDropCategories, setIsDragDropCategories] = useState(false);

    const [placePoints, setPlacePoints] = useState({});
    const [categoryPoints, setCategoryPoints] = useState({});

    const [isCreateProduct, setIsCreateProduct] = useState(false);

    async function didMount() {

        const responsePlaces = await getPlaces(jwt);
        const responseCategories = await getProductCategories(jwt);
        const responseProducts = await getProducts(jwt);

        setPlaces(responsePlaces ?? []);
        setCategories(responseCategories ?? []);
        setProducts(responseProducts ?? []);
    }

    useEffect(() => {
        didMount();
    }, []);

    useEffect(() => {

        if (!date || date.length <= 0)
            return;

        async function updateAsync() {
            const responseSends = await getProductsSends(date, currentPlaceId, jwt);
            setSends(responseSends ?? []);
        }

        updateAsync();

    }, [date]);

    const ProductItem = ({ product }) => {

        const [send, setSend] = useState();
        const [startValue, setStartValue] = useState(0);
        const [popularSendsCurrent] = useState(popularSends.filter(p => p.productId == product.id));
        const [isEditProduct, setIsEditProduct] = useState(false);

        useEffect(() => {
            const sendSearch = sends.find(s => s.productId == product.id);

            setSend(sendSearch);
            setStartValue(sendSearch ? sendSearch.value : 0);
        }, []);

        const onSubmit = async (value) => {

            const response = send?.id ? await updateProductSend(send.id, value, jwt) : await createProductSend(product.id, value, date, currentPlaceId, jwt);

            const temp = [...sends];

            if (send?.id) {
                const index = temp.findIndex(f => f.id == send.id);
                temp[index] = send;
            } else temp.push(response);

            setSend(response);
            setSends(temp);
        };

        const currentDeleteSend = async () => {

            await deleteProductSend(send.id, jwt);
            setSends([...sends.filter(s => s.id != send.id)]);
        }

        return <ListGroup.Item variant={(send ? (send.value != startValue ? 'warning' : 'success') : 'success')}>
            <Stack
                gap={3}
                direction={window.innerWidth <= 550 ? 'vertical' : 'horizontal'}
                style={
                    {
                        display: 'flex',
                        justifyContent: window.innerWidth <= 550 ? 'center' : 'space-between',
                        alignItems: window.innerWidth <= 550 ? 'stretch' : 'center'
                    }}>
                <Stack gap={1} direction="horizontal">
                    <span style={{ textAlign: 'center' }}>{product.name}</span>
                    <FaCircleInfo onClick={() => setIsEditProduct(true)} />
                </Stack>
                <Form onSubmit={e => e.preventDefault() || onSubmit(send.value)}>
                    {
                        send ?
                            <InputGroup>
                                <Form.Control
                                    type="number"
                                    value={send.value}
                                    onChange={(e) => setSend({ ...send, value: e.target.value })}
                                />
                                {
                                    startValue == send.value ? '' :
                                        <Button variant="success" type="submit">
                                            {
                                                send.id ? <IoReloadOutline /> : <IoSend />
                                            }
                                        </Button>
                                }
                                <Button variant="danger" onClick={() => send.id ? currentDeleteSend() : setSend({ value: '' })}>
                                    {
                                        send.id ? <MdDelete /> : <MdClose />
                                    }
                                </Button>
                            </InputGroup> :
                            <InputGroup style={{ width: '100%', display: 'flex', flexWrap: 'nowrap' }}>
                                {
                                    popularSendsCurrent.length > 0 ?
                                        popularSendsCurrent.map((p, i) => <Button
                                            key={i}
                                            variant="success"
                                            className="ms-auto"
                                            style={{ width: '100%' }}
                                            onClick={() => onSubmit(p.value)}>
                                            {p.value}
                                        </Button>) : ''
                                }
                                <Form.Control
                                    type="number"
                                    className="ms-auto"
                                    style={{ width: '100%' }}
                                    value={''}
                                    onChange={(e) => setSend({ value: e.target.value })}
                                />
                            </InputGroup>
                    }
                </Form>
            </Stack>
            {
                isEditProduct ?
                    <EditProduct
                        categories={categories}
                        jwt={jwt}
                        onDeleted={() => setProducts([...products.filter(f => f.id != product.id)])}
                        onHide={() => setIsEditProduct(false)}
                        onUpdate={(u) => {
                            const temp = [...products];
                            const index = temp.findIndex(f => f.id == product.id);
                            temp[index] = u;
                            setProducts(temp);
                            setIsEditProduct(false);
                        }}
                        product={product}
                        show={isEditProduct}
                    /> : ''
            }
        </ListGroup.Item>
    }

    const tempCategoryId = currentCategoryId <= 0 ? null : currentCategoryId;
    const productsOnCurrentCategory = products.filter(p => p.categoryId == tempCategoryId);

    return <div>
        <div style={{ padding: '40px 8px', margin: '0px auto', width: '100%', maxWidth: '1440px' }}>
            <Stack gap={3}>
                <Form>
                    <InputGroup>
                        <InputGroup.Text>Дата</InputGroup.Text>
                        <Form.Control
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            type="date"
                            style={{ maxWidth: '240px' }}
                        />
                    </InputGroup>
                </Form>

                <InputGroup>
                    <InputGroup.Text>Платформа</InputGroup.Text>
                    <ToggleButtonGroup type="radio" id="place-select" style={{ overflowX: 'auto' }} name="options" value={currentPlaceId} onChange={(e) => e == -1 ? '' : setCurrentPlaceId(e)}>
                        {
                            places && places.length > 0 ?
                                places.map(p => <ToggleButton
                                    key={p.id}
                                    onClick={() => setPlacePoints({ id: p.id, count: placePoints.id == p.id ? placePoints.count + 1 : 1 })}
                                    id={"tbg-radio-place-" + p.id}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    variant={currentPlaceId == p.id ? 'primary' : 'light'}
                                    value={p.id}>
                                    <span>{p.name}</span>
                                </ToggleButton>) : ''
                        }
                        <ToggleButton id={"tbg-radio-place-create"} onClick={() => setIsCreatePlace(true)} style={{ display: 'flex', alignItems: 'center' }} variant={'success'} value={-1}>
                            <FaPlus />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </InputGroup>

                <InputGroup>
                    <InputGroup.Text onClick={() => setIsDragDropCategories(true)}>Категория</InputGroup.Text>
                    <ToggleButtonGroup type="radio" id="category-select" style={{ overflowX: 'auto' }} name="options" value={currentCategoryId} onChange={(e) => e == -1 ? '' : setCurrentCategoryId(e)}>
                        {
                            categories && categories.length > 0 ?
                                categories.sort((a, b) => a.priority - b.priority).map(p => <ToggleButton
                                    key={p.id}
                                    onClick={() => setCategoryPoints({ id: p.id, count: categoryPoints.id == p.id ? categoryPoints.count + 1 : 1 })}
                                    id={"tbg-radio-category-" + p.id}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    variant={currentCategoryId == p.id ? 'primary' : 'light'}
                                    value={p.id}>
                                    <span>{p.name}</span>
                                </ToggleButton>) : ''
                        }
                        {
                            products.findIndex(a => a.categoryId == null) != -1 ? 
                            <ToggleButton id={"tbg-radio-category-no"} style={{ display: 'flex', alignItems: 'center' }} variant={currentCategoryId == -2 ? 'primary' : 'light'} value={-2}><span>Без категории</span></ToggleButton>
                            : ''
                        }
                        <ToggleButton
                            id={"tbg-radio-place-create"}
                            style={{ display: 'flex', alignItems: 'center' }}
                            variant={'success'}
                            onClick={() => setIsCreateCategory(true)}
                            value={-1}>
                            <FaPlus />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </InputGroup>

                <ListGroup>
                    {
                        productsOnCurrentCategory && productsOnCurrentCategory.length > 0 ?
                            productsOnCurrentCategory.map(p => <ProductItem key={p.id} product={p} />)
                            : <Alert variant="warning">Пусто</Alert>
                    }
                    <ListGroup.Item
                        action
                        onClick={() => setIsCreateProduct(true)}
                        variant="success"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span>Создать</span>
                    </ListGroup.Item>
                </ListGroup>
            </Stack>
            {
                isCreatePlace ?
                    <CreatePlace
                        show={isCreatePlace}
                        onHide={() => setIsCreatePlace(false)}
                        jwt={jwt}
                        onCreated={(e) => setPlaces([...places, e]) || setIsCreatePlace(false)}
                    /> : ''
            }
            {
                isCreateCategory ?
                    <CreateProductCategory
                        show={isCreateCategory}
                        onHide={() => setIsCreateCategory(false)}
                        jwt={jwt}
                        onCreated={(e) => setCategories([...categories, e]) || setIsCreateCategory(false)}
                        priority={categories[categories.length - 1].priority + 1}
                    /> : ''
            }
            {
                placePoints.count >= 3 ?
                    <EditPlace
                        show={placePoints.count >= 3}
                        onHide={() => setPlacePoints({})}
                        jwt={jwt}
                        place={places.find(p => p.id == placePoints.id)}
                        onDeleted={() => {
                            const newPlaces = [...places.filter(f => f.id != placePoints.id)];
                            setPlaces(newPlaces);
                            setPlacePoints({});
                            setCurrentPlaceId(newPlaces.length > 0 ? newPlaces[0].id : -1);
                        }}
                        onUpdate={(u) => {
                            const temp = [...places];
                            const index = temp.findIndex(f => f.id == u.id);
                            temp[index] = u;
                            setPlaces(temp);
                            setPlacePoints({});
                        }}
                        places={places}
                    /> : ''
            }
            {
                categoryPoints.count >= 3 ?
                    <EditCategory
                        show={categoryPoints.count >= 3}
                        onHide={() => setCategoryPoints({})}
                        jwt={jwt}
                        category={categories.find(p => p.id == categoryPoints.id)}
                        onDeleted={(properties) => {

                            if (properties.statusProducts == 'move')
                                setProducts([...products.map(p => { return p.categoryId == categoryPoints.id ? { ...p, categoryId: properties.newCategory } : p })])
                            else setProducts([...products.filter(p => p.categoryId != categoryPoints.id)]);

                            const newCategories = [...categories.filter(f => f.id != categoryPoints.id)];
                            setCategories(newCategories);
                            setCurrentCategoryId(newCategories.length > 0 ? newCategories[0].id : -1);
                            setCategoryPoints({});
                        }}
                        onUpdate={(u) => {

                            const temp = [...categories];
                            const index = temp.findIndex(f => f.id == u.id);
                            temp[index] = u;
                            setCategories(temp);
                            setCategoryPoints({});
                        }}
                        categories={categories}
                    /> : ''
            }
            {
                isCreateProduct ?
                    <CreateProduct
                        categories={categories}
                        jwt={jwt}
                        onCreated={(c) => {
                            setProducts([...products, c]);
                            setIsCreateProduct(false);
                        }}
                        onHide={() => setIsCreateProduct(false)}
                        show={isCreateProduct}
                        categoryId={currentCategoryId}
                    /> : ''
            }
            {
                isDragDropCategories ?
                    <DragDropCategories
                        categories={categories}
                        jwt={jwt}
                        onHide={() => setIsDragDropCategories(false)}
                        onUpdate={(u) => {
                            setCategories(u);
                            setIsDragDropCategories(false);
                        }}
                        show={isDragDropCategories}
                    /> : ''
            }
        </div>
    </div>;
}

export default SellProducts;