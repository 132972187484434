import { HttpData, HttpNoData } from '../../../Core';

export async function getProducts() {
    
    const response = await HttpNoData(`/api/v1/ProductsWarehouse/Products`, 'GET');

    return response.data.products;
}

export async function getCategories(){

    const response = await HttpNoData(`/api/v1/ProductsWarehouse/ProductCategories`, 'GET');

    return response.data.categories;
}

export async function createProduct(body) {
    
    const response = await HttpData(`/api/v1/ProductsWarehouse/Products`, 'POST', null, body);

    return response.data;
}

export async function updateProduct(body) {
    
    const response = await HttpData(`/api/v1/ProductsWarehouse/Products`, 'PUT', null, body);

    return response.statusSuccessful;
}

export async function deleteProduct(id) {
    
    const response = await HttpNoData(`/api/v1/ProductsWarehouse/Products/${id}`, 'DELETE', null);

    return response.statusSuccessful;
}

export async function createWorkerExtraWork(jwt, body) {
    
    const response = await HttpData(`/api/v1/ExtraWorks`, 'POST', jwt, body);

    return response.data;
}

export async function createManagerProduct(jwt, body) {
    
    const response = await HttpData(`/api/v1/Products`, 'POST', jwt, body);

    return response.data;
}

export async function createCategory(body) {

    const response = await HttpData(`/api/v1/ProductsWarehouse/ProductCategories`, 'POST', null, body);

    return response.data;
}

export async function createPull(body) {
    
    const response = await HttpData(`/api/v1/ProductsWarehouse/productPulls`, 'POST', null, body);

    return response.data;
}

export async function getAmounts() {
    
    const response = await HttpNoData(`/api/v1/ProductsWarehouse/products/amounts`, 'GET');

    return response.data.products;
}