import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { HttpData, HttpNoData } from '../../../Core.js'
import styles from '../Style/LeadStyle.module.css'

function Lead() {
	const [currentDate, setCurrentDate] = useState(new Date())
	const [isHiddenCalendar, setIsHiddenCalendar] = useState(true)
	const [leadSends, setLeadSends] = useState([])
	const [jwt, setJwt] = useState(localStorage.getItem('jwtToken'))

	const [isLoad, setIsLoad] = useState(true)

	async function DidMount() {
		var response = await HttpNoData(
			'/api/v0/LeadSends?date=' +
				currentDate.toLocaleDateString().split('.').reverse().join('-'),
			'GET',
			jwt
		)

		if (response.statusSuccessful && !response.isDataEmpty) {
			setLeadSends(response.data)
			setIsLoad(false)
		} else console.log(response.error)
	}

	useEffect(() => {
		DidMount()
	}, [])

	const ClickSave = async () => {
		var responseUpdate = await HttpData(
			'/api/v0/LeadSends',
			'POST',
			jwt,
			leadSends
		)

		if (responseUpdate.statusSuccessful) {
			await DidMount()
			setIsLoad(false)
		} else console.log(responseUpdate.error)
	}

	const UpdateCurrentDate = async newDate => {
		if (isLoad) return

		var responseDateData = await HttpNoData(
			'/api/v0/LeadSends?date=' +
				newDate.toLocaleDateString().split('.').reverse().join('-'),
			'GET',
			jwt
		)

		if (responseDateData.statusSuccessful && !responseDateData.isDataEmpty) {
			setLeadSends(responseDateData.data)
			setCurrentDate(newDate)
		} else console.log(responseDateData.error)
	}

	const UpdateValue = async (index, newValue) => {
		// 1. Make a shallow copy of the items
		let items = [...leadSends]
		// 2. Make a shallow copy of the item you want to mutate
		let item = { ...items[index] }
		// 3. Replace the property you're intested in
		item.value = newValue
		// 4. Put it back into our array. N.B. we *are* mutating the array here,
		//    but that's why we made a copy first
		items[index] = item
		// 5. Set the state to our new copy
		setLeadSends(items)
	}

	const leadsSendUI = leadSends.map((item, index) => (
		<div key={index} className={styles.item}>
			<div className={styles.item_text}>{item.leadName}</div>
			<input
				type='number'
				className={styles.item_input}
				value={item.value}
				onChange={e => {
					UpdateValue(index, e.target.value)
				}}
			/>
		</div>
	))

	return (
		<React.Fragment>
			<div className={styles.container}>
				<div className={styles.content}>
					<button
						onClick={() => {
							setIsHiddenCalendar(!isHiddenCalendar)
						}}
						className={styles.date}
					>
						{currentDate.toLocaleDateString()}
					</button>
					<div className={styles.leads}>{leadsSendUI}</div>
					<button
						disabled={isLoad}
						className={styles.save_button}
						onClick={() => {
							setIsLoad(true)
							ClickSave()
						}}
					>
						сохранить
					</button>
				</div>
				<div
					onClick={e => {
						if (e.target.className.includes('calendarBody'))
							setIsHiddenCalendar(true)
					}}
					className={styles.calendarBody}
					hidden={isHiddenCalendar}
				>
					<Calendar
						onChange={date => UpdateCurrentDate(date)}
						value={currentDate}
					/>
				</div>
			</div>
		</React.Fragment>
	)
}

export default Lead
