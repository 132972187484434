
export function updatePropertyInList(array, propertyId, id, property, value, callbackFnSum){

    const index = array.findIndex(f => f[propertyId] == id);

        if(index == -1)
        {
            const push = { [propertyId]: id };
            push[property] = value;
            array.push(push);
        }
        else array[index][property] = value;

        if(callbackFnSum)
            callbackFnSum(+(array.reduce((a, b) => a + +b[property], 0).toFixed(2)));

        return [...array];
}

export function generatePastelColor() {
    let R = Math.floor((Math.random() * 127) + 127);
    let G = Math.floor((Math.random() * 127) + 127);
    let B = Math.floor((Math.random() * 127) + 127);
    
    let rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16)}`;      
  }