import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
const EditWorkersView = ({ show, setShow, data, setData, workers }) => {
	const [allChecked, setAllChecked] = useState(false)

	const [notificationData, setNotificationData] = useState(data)

	useEffect(() => {
		setNotificationData(data)
	}, [data])

	return (
		<Modal show={show}>
			<Modal.Header closeButton>
				<b>Выберите работников</b>
			</Modal.Header>
			<Modal.Body>
				<Form>
					{workers && workers.length > 0 ? (
						<>
							<Form.Check
								label='Все'
								name='all'
								onChange={e => {
									const { checked } = e.target
									setAllChecked(checked)
									if (checked) {
										setNotificationData({
											...notificationData,
											workersIdes: [],
										})
									} else {
										setNotificationData({
											...notificationData,
											workersIdes: [],
										})
									}
								}}
							/>
							{workers &&
								workers.length > 0 &&
								workers
									.sort((a, b) => a.secondName.localeCompare(b.secondName))
									.map(o => (
										<Form.Check
											key={o.id}
											label={`${o.secondName} ${o.firstName}  ${o.thirdName}`}
											name={o.id}
											checked={
												(notificationData.workersIdes &&
													notificationData.workersIdes.includes(o.id)) ||
												(allChecked &&
													(!notificationData.workersIdes ||
														notificationData.workersIdes.length === 0))
											}
											onChange={e => {
												const { checked } = e.target
												if (checked) {
													setNotificationData({
														...notificationData,
														workersIdes: [
															...(notificationData.workersIdes || []),
															o.id,
														],
													})
												} else {
													setNotificationData({
														...notificationData,
														workersIdes: (
															notificationData.workersIdes || []
														).filter(id => id !== o.id),
													})
												}
												setAllChecked(false)
											}}
										/>
									))}
						</>
					) : (
						<p>Упс... работники не загрузились</p>
					)}
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={() => setShow(false)}>
					Закрыть
				</Button>
				<Button
					variant='primary'
					type='submit'
					onClick={() => {
						setShow(false)
						setData(notificationData)
					}}
				>
					Сохранить
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default EditWorkersView
