import { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form, ListGroup } from 'react-bootstrap'
import { downloadExcel } from './SalaryAPI'
import WorkersModal from './WorkersModal'
import { HttpNoData } from '../../../Core'

export default function Excel({ isMobile, workers, workerCategories, jwt }) {

	const [showModal, setShowModal] = useState(false)
	const [selectedWorkers, setSelectedWorkers] = useState([])

	const [startNormalHours, setStartNormalHours] = useState();

	const [excelData, setExcelData] = useState({
		month: new Date().toISOString().split('T')[0],
		normalHours: 0,
		workers: [],
	});

	useEffect(() => {

		async function didMount() {
			const response = await HttpNoData('/api/v1/salaryBids', 'GET', jwt);

			if (response.statusSuccessful) {
				setExcelData({ ...excelData, normalHours: +response.data });
				setStartNormalHours(response.data);
			}
		}

		didMount();
	}, []);

	const handleModalShow = () => setShowModal(true);

	const handleWorkerSelect = workers => {
		setSelectedWorkers(workers)
		setShowModal(false)
		setExcelData({ ...excelData, workers: workers })
	}

	const downloadExcelFile = async() => {

		const workersIds = excelData.workers.map(worker => worker.id);
		const data = { ...excelData, workers: workersIds };

		await downloadExcel(jwt, data);

		if(startNormalHours != excelData.normalHours)
			await HttpNoData(`/api/v1/salaryBids/${excelData.normalHours}`, 'POST', jwt);
	}

	const handleSubmit = event => {
		event.preventDefault()

		const form = event.currentTarget
		if (form.checkValidity() === false) {
			event.stopPropagation()
		}
		downloadExcelFile()
	}

	return (
		<div>
			<h1 style={{ textAlign: 'center' }}>Excel</h1>
			<Form onSubmit={handleSubmit}>
				<Form.Group className='mb-3'>
					<Form.Label htmlFor='fineDate'>Дата</Form.Label>
					<Form.Control
						required
						type='date'
						id='fineDate'
						onChange={e => setExcelData({ ...excelData, month: e.target.value })}
					/>
				</Form.Group>

				<Form.Group className='mb-3'>
					<Form.Label htmlFor='normOfHours'>Норма часов</Form.Label>
					<Form.Control
						required
						type='number'
						id='normOfHours'
						value={excelData.normalHours}
						onChange={e =>
							setExcelData({
								...excelData,
								normalHours: Number(e.target.value),
							})
						}
					/>
				</Form.Group>
				<WorkersModal
					isMobile={isMobile}
					categories={workerCategories}
					show={showModal}
					onHide={() => setShowModal(false)}
					workers={workers}
					onSelect={handleWorkerSelect}
				/>
				<div>
					<h3>Сотрудники:</h3>
					<Button className='mb-3' onClick={handleModalShow}>
						Редактировать
					</Button>
					<ListGroup>
						{selectedWorkers.length > 0 ? (
							<ul
								style={
									!isMobile ? { maxHeight: '450px', overflowY: 'auto' } : {}
								}
							>
								{selectedWorkers.map(worker => (
									<li
										className='mb-1'
										key={worker.id}
									>{`${worker.firstName} ${worker.secondName} ${worker.thirdName}`}</li>
								))}
							</ul>
						) : (
							<Alert variant='danger'>Не выбрано</Alert>
						)}
					</ListGroup>
				</div>
				<Button disabled={!excelData || excelData.month == '' || selectedWorkers.length <= 0} variant={'success'} style={{ width: '100%' }} type='submit'>
					Скачать
				</Button>
			</Form>
		</div>
	)
}
