import React from 'react'
import { getDaysInPart } from '../utils'

function RowWorker({ worker, tab, statuses, isMobile, currentMonthDays, localSchedule, handleStatusChange }) {

    const workerName = worker.name == '' ? worker.firstName + ' ' + worker.secondName + ' ' + worker.thirdName : worker.name;

    const DayItem = ({ day }) => {

        const currentDaySchedule = localSchedule?.find(s => s.workerId === worker.id)?.schedule.find(d => d.day === day);

        return <td style={{backgroundColor: statuses.find(s => s.key == currentDaySchedule?.status)?.color ?? 'black'}}>
        <select
            style={{ width: '100%'}}
            value={currentDaySchedule?.status ?? -1}
            onChange={e => handleStatusChange(worker.id, day, currentDaySchedule?.status, e.target.value)
            }
        >
            {statuses.map(status => (
                <option key={status.key} value={status.key}>
                    {status.char}
                </option>
            ))}
            {
                statuses.findIndex(f => f.key == currentDaySchedule?.status) == -1 ?
                <option value={-1}>Х</option> : ''
            }
        </select>
    </td>
    }

    return <tr key={worker.id}>
        <td>{workerName}</td>
        { 
            getDaysInPart(tab.eventKey, currentMonthDays, isMobile)
                .map((day, i) => <DayItem key={i} day={day}/>)
        }
    </tr>
}

export default RowWorker
