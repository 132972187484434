import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { HttpData, HttpNoData } from "../../../../Core";
import { generatePastelColor } from "../utils";

export default function TypeModalEdit({ jwt, type, show, onHide, onUpdated, onDeleted }){

    const [name, setName] = useState(type.name);
    const [color, setColor] = useState(type.color);
    const [colors, setColors] = useState(Array.from({ length: 15 }).map(a => generatePastelColor()));
    const [description, setDescription] = useState(type.description);

    const onSubmit = async(e) => {
        e.preventDefault();

        const response = await HttpData(`/api/v1/calculateOrderCost/TypeProductCategories/${type.id}`, 'PUT', jwt, {
            name: name,
            description: description,
            color: color
        });

        if(response.statusSuccessful)
            onUpdated({
                ...type,
                name: name,
                description: description,
                color: color
            });
        else alert(`Не получилось обновить: ${response.error}`)
    }

    const onDelete = async() => {

        const response = await HttpNoData(`/api/v1/calculateOrderCost/TypeProductCategories/${type.id}`, 'DELETE', jwt);

        if(response.statusSuccessful)
            onDeleted();
        else alert(`Не получилось удалить: ${response.error}`)
    }

    return <Modal show={show} onHide={onHide}>
        <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
            <Modal.Title>Обновление {type.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3">
                <Form.Label>Название</Form.Label>
                <Form.Control 
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Цвет</Form.Label>
                <Form.Control 
                    required
                    type="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    list="colors"
                    />
                <datalist id="colors">
                    {
                        colors.map((a, index) => <option key={index}>{a}</option>)
                    }
                </datalist>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Описание</Form.Label>
                <Form.Control 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Отмена</Button>
            <Button variant="danger" onClick={onDelete}>Удалить</Button>
            <Button variant="success" type="submit">Сохранить</Button>
        </Modal.Footer>
        </Form>
    </Modal>
}