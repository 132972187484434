import { useState } from "react";
import { createWorkerExtraWork } from "./rawMaterialWarehouseApi";
import { Button, Form, Modal } from "react-bootstrap";

export default function CreateWorkerExtraWorkView({ jwt, name, onCreated, show, onHide }) {

    const [extraWork, setExtraWork] = useState({ name: name, coefficient: 1 });

    const submitExtraWork = async(e) => {
        e.preventDefault();

        const responseExtraWork = await createWorkerExtraWork(jwt, extraWork);
        
        if(responseExtraWork && responseExtraWork.id > 0)
            onCreated(responseExtraWork);
        else alert('Что-то пошло не по плану');
    }

    return <Modal show={show} onHide={onHide} size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
        <Form onSubmit={(e) => submitExtraWork(e)}>
        <Modal.Header closeButton>
            <Modal.Title>Создание новой работы</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput10">
                <Form.Label>Название</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Работа 1999"
                    value={extraWork.name}
                    onChange={(e) => setExtraWork({ ...extraWork, name: e.target.value })}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput11">
                <Form.Label>Коэффициент</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="1"
                    value={extraWork.coefficient}
                    onChange={(e) => setExtraWork({ ...extraWork, coefficient: e.target.value })}
                />
            </Form.Group>
            
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Отмена</Button>
            <Button variant="success" type="submit">Создать</Button>
        </Modal.Footer>
        </Form>
    </Modal>;
}