import React, { useEffect, useState } from "react";
import { HttpData, HttpNoData } from '../../../Core.js';
import styles from './ShiftCurrent.module.css';

function ShiftCurrent() {
    
    const [shifts, setShifts] = useState([]);
    const [jwt, setJwt] = useState(localStorage.getItem('jwtToken'));

    useEffect(() => {

        async function DidMount(){
            const response = await HttpNoData('/api/v0/Shifts/current/all', 'GET', jwt);

            if(response.statusSuccessful)
                setShifts(response.data);
            else console.log(response.error);
        }

        DidMount();
    }, []);

    const CloseShift = async(shift) => {

        const response = await HttpData('/api/v0/Shifts/current/root?shiftId='+ shift.id, 'DELETE', jwt);

        if(response.statusSuccessful)
        {
            const update = shifts.filter(item => item.id != shift.id);
            setShifts([...update]);
        }
        else console.log(response.error);
    };
    
    return <div className={styles.psevdoBody}>
        <div className={styles.panelMove}>
            <label className={styles.dateString}>{new Date().toLocaleString(undefined, { year: 'numeric', month: 'long' })}</label>
        </div>
        <div id="listContainer" className={styles.shift_container}>
            {shifts.length <= 0 ?
            <div className={styles.emptyShift}>
                Сегодня нет работ
            </div>:
                shifts.map(shift => <div className={styles.shift} key={shift.id}>
                        <div className={styles.field}>
                            {shift.worker}
                        </div>
                        <div className={styles.field}>
                            Начало: {new Date(shift.start).toLocaleString(undefined, { year: 'numeric', month: 'numeric', day:'numeric', hour:'numeric', minute:'numeric' })}
                        </div>
                        <div className={styles.field}>
                            <button className={styles.buttonClose} onClick={() => CloseShift(shift)}>закрыть</button>
                        </div>
                    </div>)
            }
        </div>
    </div>
}

export default ShiftCurrent;