import { HttpNoData } from "../Core";

export async function getFormulaAmounts(date, jwt) {

    const response = await HttpNoData(`/api/v1/formulas/amounts/${date}`, 'GET', jwt);

    return response.data;
}

export async function ProductOnDate(timeSpan, date, jwt){

    const response = await HttpNoData('/api/v0/analyze/products/' + timeSpan + (date ? '?date=' + date : ''), 'GET', jwt);
    
    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function getDataDay(dateDay, jwt){

    const response = await HttpNoData('/api/v0/analyze/leads/' + dateDay, 'GET', jwt);
    
    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}