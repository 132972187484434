import { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { deleteProductCategory, updateProductCategory } from "../api";

export default function EditCategory({ show, onHide, jwt, onUpdate, onDeleted, categories, category }) {

    const [categoryLocal, setCategoryLocal] = useState({...category});
    const [onDelete, setOnDelete] = useState(null);

    const onSubmit = async() => {

        const response = await updateProductCategory(categoryLocal.id, categoryLocal.name, categoryLocal.priority, jwt);

        if(response)
            onUpdate(categoryLocal);
        else alert('Не удалось создать, разрыв с сервером')
    }


    if(onDelete)
        return <Modal
        centered
        show={onDelete != null}
        onHide={() => setOnDelete(null)}
    >
        <Form onSubmit={e => e.preventDefault() || onSubmit()}>
            <Modal.Header closeButton>
                <Modal.Title>Удаление</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Что делать с продукцией в этой категории?</Form.Label>
                    <Form.Select
                        id='category-move-delete'
                        checked={onDelete.statusProducts}
                        onChange={(e) => setOnDelete({...onDelete, statusProducts: e.target.value})}
                    >
                        <option value={'delete'}>Удалить продукцию и ее отправки</option>
                        <option value={'move'}>Переместить</option>
                    </Form.Select>
                </Form.Group>
                {
                    onDelete.statusProducts == 'delete' ? <Alert variant="danger">Данное действие отразиться на статистике</Alert>
                    :  <Form.Group className="mb-3">
                    <Form.Label>Куда</Form.Label>
                    <Form.Select
                        required
                        id='category-move-new-category'
                        checked={onDelete.newCategory}
                        onChange={(e) => setOnDelete({...onDelete, newCategory: e.target.value})}
                    >
                       {
                         categories && categories.filter(f => f.id != category.id).length > 0 ?
                         categories.filter(f => f.id != category.id).map(p => <option key={p.id} value={p.id}>{p.name}</option>) : ''
                       }
                    </Form.Select>
                </Form.Group>
                }
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOnDelete(null)}><span>Закрыть</span></Button>
                <Button variant="danger" onClick={async() => {
                    await deleteProductCategory(category.id, onDelete.statusProducts, onDelete.newCategory, jwt);
                    onDeleted(onDelete);
                }}><span>Удалить</span></Button>
            </Modal.Footer>
        </Form>
    </Modal>

    return <Modal
        centered
        show={show}
        onHide={onHide}
    >
        <Form onSubmit={e => e.preventDefault() || onSubmit()}>
            <Modal.Header closeButton>
                <Modal.Title>{category.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={categoryLocal.name}
                        onChange={(e) => setCategoryLocal({...categoryLocal, name: e.target.value})}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => setOnDelete({ statusProducts: 'delete', newCategory: categories.filter(f => f.id != category.id)[0].id})}><span>Удалить</span></Button>
                <Button variant="secondary" onClick={onHide}><span>Закрыть</span></Button>
                <Button variant="success" type="submit"><span>Сохранить</span></Button>
            </Modal.Footer>
        </Form>
    </Modal>
}
