import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { createPlace } from "../api";

export default function CreatePlace({ show, onHide, jwt, onCreated }) {

    const [name, setName] = useState('');
    const [isMarketPlace, setIsMarketPlace] = useState(false);

    const onSubmit = async() => {

        const response = await createPlace(name, isMarketPlace, jwt);

        if(response)
            onCreated(response);
        else alert('Не удалось создать, разрыв с сервером')
    }

    return <Modal
        centered
        show={show}
        onHide={onHide}
    >
        <Form onSubmit={e => e.preventDefault() || onSubmit()}>
            <Modal.Header closeButton>
                <Modal.Title>Новая платформа</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check
                        type='checkbox'
                        id='is-marketplace-in-create-platform'
                        label='Маркетплейс'
                        checked={isMarketPlace}
                        onChange={(e) => setIsMarketPlace(e.target.checked)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary"><span>Закрыть</span></Button>
                <Button variant="success" type="submit"><span>Создать</span></Button>
            </Modal.Footer>
        </Form>
    </Modal>
}
