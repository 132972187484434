import { Alert } from "react-bootstrap";
import RowWorker from "./RowWorker";
import { useEffect, useState } from "react";
import { getDaysInPart } from "../utils";

function RowsWorkerCategories({ category, workers, statuses, currentMonthDays, handleStatusChange, isMobile, localSchedule, tab }) {

    const [countWorkerInDay, setCountWorkerInDay] = useState([]);

    
    const updateCounts = () => {
        const result = [];

        const days = getDaysInPart(tab.eventKey, currentMonthDays, isMobile);
        
        for (let i = 0; i < days.length; i++) {
            result.push({
                day: days[i],
                count: localSchedule
                .filter(w => workers.findIndex(f => f.id == w.workerId) != -1)
                .filter(f => f.schedule.find(s => s.day == days[i])?.status == 'working').length
            })
        }

        setCountWorkerInDay(result);
    };
    
    useEffect(updateCounts, []);
    useEffect(updateCounts, [isMobile]);
    
    return <>
        <tr>
            <td colSpan={currentMonthDays}>
                <h5>{category.name}</h5>
            </td>
        </tr>
        {
            workers && workers.length > 0 ?
                workers.map(worker => <RowWorker key={worker.id}
                    categoryName={category.name}
                    statuses={statuses}
                    currentMonthDays={currentMonthDays}
                    handleStatusChange={(workerId, day, oldStatus, newStatus) => {

                        if(oldStatus == 'working')
                        {
                            const temp = [...countWorkerInDay];
                            temp.find(f => f.day == day).count -= 1;
                            setCountWorkerInDay(temp);
                        } else if(newStatus == 'working')
                            {
                                const temp = [...countWorkerInDay];
                                temp.find(f => f.day == day).count += 1;
                                setCountWorkerInDay(temp);
                            }
                        handleStatusChange(workerId, day, newStatus);
                    }}
                    isMobile={isMobile}
                    localSchedule={localSchedule}
                    tab={tab}
                    worker={worker}
                />) : <tr>
                    <td colSpan={currentMonthDays}>
                        <Alert>Нет сотрудников</Alert>
                    </td>
                </tr>
        }
        {
            countWorkerInDay && countWorkerInDay.length > 0 ?
                <tr>
                    <td>
                        Кол-во
                    </td>
                    {
                        countWorkerInDay.map(d => <td key={d.day}>
                            {d.count}
                        </td>)
                    }
                </tr>
                : ''
        }
    </>
}

export default RowsWorkerCategories;